.kalniyojanAdminUpdateArticleContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    padding: 10px;
    min-height: calc(100vh - 266px);
}

.kalniyojanAdminUpdateArticleContainer > .kalniyojanAdminUpdateArticleContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
}

.kalniyojanAdminUpdateArticleContainerContent > .updateArticleTitle{
  font-size: 35px;
  font-family: "Amaranth";
  margin-bottom: 2rem;
  text-align: center;
}

.kalniyojanAdminUpdateArticleContainerContent > .updateArticleErrorShower{
    display: none;
}

.kalniyojanAdminUpdateArticleContainerContent > .updateArticleErrorShowerSelected{
    display: flex;
    flex-direction: column;
    width: 800px;
    margin-bottom: .5rem;
    font-family: "Amaranth";
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 1rem;
    background-color: rgb(247, 84, 84);
    font-size: 16px;
}

.kalniyojanAdminUpdateArticleContainerContent > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 2rem;
}

.adminUpdateArticleNameRow{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.adminUpdateArticleNameRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.adminUpdateArticleNameRow > label > span{
    color: red;
}

.adminUpdateArticleNameRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adminUpdateArticlePublishDateRow{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  width: 100%;
  column-gap: 20px;
  margin-top: 10px;
}

.adminUpdateArticlePublishDateRow > div > label> span{
    color: red;
}

.adminUpdateArticlePublishDateRow > .adminUpdateArticlePublishYear{
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.adminUpdateArticlePublishYear > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminUpdateArticlePublishYear > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adminUpdateArticlePublishDateRow > .adminUpdateArticlePublishMonth{
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.adminUpdateArticlePublishMonth > label {
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminUpdateArticlePublishMonth > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adminUpdateArticlePublishDateRow > .adminUpdateArticlePublishDay{
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.adminUpdateArticlePublishDay > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminUpdateArticlePublishDay > input{
  width: 100%;
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanAdminUpdateArticleContainerContent > form > .adminUpdateArticleImage{
    width: 100%;
    margin-bottom: 2rem;
}

.adminUpdateArticleImage > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminUpdateArticleImage > label > span{
  color: red;
}

.adminUpdateArticleImage > input{
  width: 100%;
  border-color: rgba(108, 75, 137, 1);
}

.adminUpdateArticleImage > .updateArticleFileNote{
    font-size: 11px;
    color: red;
}

.kalniyojanAdminUpdateArticleSubmitBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.kalniyojanAdminUpdateArticleSubmitBtn > button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 700px){
  .kalniyojanAdminUpdateArticleContainerContent form{
    width: 500px;
  }
}

@media(max-width: 600px){
  .kalniyojanAdminUpdateArticleContainerContent form{
    width: 400px;
  }
}

@media(max-width: 500px){
  .kalniyojanAdminUpdateArticleContainerContent form{
    width: 300px;
  }
}

@media(max-width: 400px){
  .kalniyojanAdminUpdateArticleContainerContent form{
    width: 200px;
  }
}

@media(max-width: 300px){
  .kalniyojanAdminUpdateArticleContainerContent form{
    width: 150px;
  }

  .kalniyojanAdminUpdateArticleContainerContent form .adminUpdateArticlePublishDateRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}