.kalniyojanServicesContainer{
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-height: calc(100vh - 234.4px);
}

.kalniyojanServicesContainer > .kalniyojanServicesContainerContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.kalniyojanServicesHeader{
  text-align: center;
  font-size: 42px;
  font-family: "Rubik";
  font-weight: normal;
  margin-top: 1em;
  margin-bottom: 2em;
}

.kalniyojanServices{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kalniyojanServicesLeftSide{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.kalniyojanServicesLeftSide > div{
  font-size: 30px;
  text-align: center;
  border-radius: 9px;
  color: #272729;
  background-color: #F8EDE3;
  width: 90%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  height: 200px;
  font-family: "Rubik";
  margin-bottom: 2em;
}

.kalniyojanServicesLeftSide > .kalniyojanServiceTwo, 
.kalniyojanServiceThree, 
.kalniyojanServiceFour, 
.kalniyojanServiceSix,
.kalniyojanServiceFive{
  display: flex;
  justify-content: center;
  align-items: center;
}

.kalniyojanServicesLeftSide > .kalniyojanServiceTwo,
.kalniyojanServicesLeftSide > .kalniyojanServiceFour,
.kalniyojanServicesLeftSide > .kalniyojanServiceFive{
  color: #065598;
}

.kalniyojanServicesLeftSide > .kalniyojanServiceTwo:hover,
.kalniyojanServicesLeftSide > .kalniyojanServiceFour:hover,
.kalniyojanServicesLeftSide > .kalniyojanServiceFive:hover{
  cursor: pointer;
}


.kalniyojanServicesLeftSide > div:hover{
  transform: scale(1.1);
}

.kalniyojanServicesRightSide{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.kalniyojanServicesRightSide div{
  font-size: 30px;
  text-align: center;
  border-radius: 9px;
  color: #272729;
  background-color: #F8EDE3;
  font-family: "Rubik";
  width: 90%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  height: 200px;
  margin-bottom: 2em;
}

.kalniyojanServicesRightSide > .kalniyojanServiceEight,
.kalniyojanServicesRightSide > .kalniyojanServiceTen,
.kalniyojanServicesRightSide > .kalniyojanServiceOne,
.kalniyojanServicesRightSide > .kalniyojanServiceSeven,
.kalniyojanServicesRightSide > .kalniyojanServiceNine{
  display: flex;
  justify-content: center;
  align-items: center;
}

.kalniyojanServicesRightSide > .kalniyojanServiceEight,
.kalniyojanServicesRightSide > .kalniyojanServiceTen{
  color: #065598;
}

.kalniyojanServicesRightSide > .kalniyojanServiceEight:hover,
.kalniyojanServicesRightSide > .kalniyojanServiceTen:hover{
  cursor: pointer;
}

.kalniyojanServicesRightSide div:hover{
  transform: scale(1.1);
}

.kalniyojanServicesContainer > .servicesDetailsHighlight{
  margin-top: 4em;
}

.kalniyojanServicesContainer > .servicesDetailsHighlight > p{
  text-align: center;
  font-size: 30px;
  font-family: iosevka;
  font-family: cursive;
  margin: 0;
}

.kalniyojanServicesContainer > .servicesDetailsHighlight > hr{
  width: 30%;
}

.servicesDetailsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.servicesDetailsContainerContent{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  justify-content: center;
  row-gap: 2rem;
  margin-top: 1em;
  column-gap: 1rem;
  padding: 10px;
  width: 100%;
}

.servicesDetailsContainerContent > .patrikaDetail{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: #DCD6F7;
  border-radius: 15px;
  grid-column: 1 / span 1;
  height: 300px;
}

.patrikaDetail > .kalniyojanPatrikaServiceImgHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.patrikaDetail > .kalniyojanPatrikaServiceImgHeader > img{
  width: 180px;
  height: 180px;
}

.patrikaDetail > .kalniyojanPatrikaServiceImgHeader > h2{
  font-size: 28px;
  text-align: center;
  font-family: "Rubik";
  font-weight: 400;
}

.patrikaDetail > .patrikaVariantLang{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #F4EEFF;
  border-radius: 15px;

}

.patrikaVariantLang > .patrikaVariants{
  font-size: 20px;
  font-weight: 400;
  font-family: "Rubik";
  padding: 10px;
  text-align: center;
  text-transform: capitalize;
}

.patrikaVariantLang > .langList{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 3em;
  list-style: none;
  font-family: "Rubik";
  font-size: 18px;
}


.servicesDetailsContainerContent > .softwareDetails{
  display: flex;
  flex-direction: row;
  grid-column: 2 / span 2;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: #A0C49D;
  border-radius: 15px;
  height: 300px;
}

.softwareDetails > .kalniyojanSoftwareDetailsImgHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.softwareDetails > .kalniyojanSoftwareDetailsImgHeader > img{
  height: 180px;
  width: 180px;
}

.softwareDetails > .kalniyojanSoftwareDetailsImgHeader > h2{
  font-size: 28px;
  text-align: center;
  font-family: "Rubik";
  font-weight: 400;
}

.softwareDetails > .softwareVariantLang{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #C4D7B2;
  border-radius: 15px;
}

.softwareDetails > .softwareVariantLang > .softwareVariants{
  font-size: 20px;
  font-weight: 400;
  font-family: "Rubik";
  padding: 10px;
  text-align: center;
  text-transform: capitalize;
}

.softwareDetails > div > ul{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 3em;
  list-style: none;
  font-family: "Rubik";
  font-size: 18px;
}

.servicesDetailsContainerContent > .TeachingDetails{
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  grid-column: 1 / span 1;
  background-color: #4A55A2;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  height: 300px;
}

.TeachingDetails > .kalniyojanTeachingDetailsImgHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.TeachingDetails > .kalniyojanTeachingDetailsImgHeader > img{
  /* margin-top: 1rem; */
  width: 180px;
  height: 180px;
}

.TeachingDetails > .kalniyojanTeachingDetailsImgHeader > h2{
  font-size: 28px;
  text-align: center;
  font-family: "Rubik";
  font-weight: 400;
}

.TeachingDetails > .teachingVariants{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 400px;
  font-family: "Rubik";
  background-color: #7895CB;
  text-transform: capitalize;
  border-radius: 15px;
}
.TeachingDetails > .teachingVariants > .teachingVariant{
  padding: 10px;
}

.servicesDetailsContainerContent > .counsellingDetails{
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column: 2 / span 2;
  background-color: #B4B4B8;
  border-radius: 15px;
  justify-content: space-around;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  height: 300px;
}

.counsellingDetails > .kalniyojanCounsellingDetailsImgHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.counsellingDetails > .kalniyojanCounsellingDetailsImgHeader > img{
  width: 180px;
  height: 180px;
}

.counsellingDetails > .kalniyojanCounsellingDetailsImgHeader > h2{
  font-size: 28px;
  text-align: center;
  font-family: "Rubik";
  font-weight: 400;
}

.counsellingDetails > .counsellingVariants{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 400px;
  font-family: "Rubik";
  text-transform: capitalize;
  background-color: #C7C8CC;
  border-radius: 15px;
}

.kalniyojanServicesContainer > .magzineContainer{
  margin-top: 1cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.magzineContainer > .magzine{
  display: flex;
  flex-direction: row;
  background-color: #DAC0A3;
  border-radius: 15px;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
  text-transform: capitalize;
  width: 100%;
  height: 500px;
}

.magzineContainer > .magzine > .kalniyojanMagzineHeaderBrandYearCreator{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

.magzine > .kalniyojanMagzineHeaderBrandYearCreator > h1{
  font-size: 38px;
  font-family: "Rubik";
  font-weight: 400;
}

.magzine > .kalniyojanMagzineHeaderBrandYearCreator > .kalniyojanMagzinePublishDate{
  font-size: 22px;
  text-align: center;
  font-family: "Rubik";
}

.kalniyojanMagzineHeaderBrandYearCreator > .magzineNameAndCreator > .Creator{

  font-size: 24px;
  font-weight: bold;
  font-family: "Rubik";
}

.magzine > .magzineSubscriptionInfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #EADBC8;
  border-radius: 15px;
}

.magzineSubscriptionInfo > .magzineContents{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 25px;
  font-family: "Rubik";
  color: rgb(255, 59, 157);
  width: 100%;
  margin-top: 10px;
}

.magzineContents > .magzineContent{
  color: rgb(0, 0, 170);
}

.magzineSubscriptionInfo > .magzineYearlyRate{
  justify-self: center;
  text-align: center;
  width: 300px;
  background-image: linear-gradient(50deg,#f5af19,#f12711);
  padding: 10px;
  border-radius: 50%;
  font-size: 18px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: "Rubik";
}

.magzineYearlyRate > .yearlyPrice{
  font-size: 2rem;
}

.magzineSubscriptionInfo > .bankInfo{
  text-align: left;
  font-size: 20px;
  font-family: "Rubik";
  color: rgb(0, 0, 170);
  padding: 10px;
}

.bankInfo .bankInfoTitle{
  color: green;
}

.magzineSubscriptionInfo > .whereToFindQrCode{
  padding: 10px;
  font-size: 18px;
  font-family: "Rubik";
}

@media(max-width: 1320px){

  .servicesDetailsContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .servicesDetailsContainerContent > .patrikaDetail{
    margin-bottom: 1cm;
  }
  
  .servicesDetailsContainerContent > .counsellingDetails{
    margin-bottom: 1cm;
  }

  .softwareDetails {
    margin-bottom: 1cm;
  }

  .TeachingDetails {
    margin-bottom: 1cm;
  }

}

@media(max-width: 1179px){

  .magzineContainer > .magzine{
    height: 600px;
  }

  .magzineContainer > .magzine > .kalniyojanMagzineHeaderBrandYearCreator > .magzineNameAndCreator{
    text-align: center;
  }
}

@media(max-width:810px){

  .magzineContainer > .magzine{
    height: 700px;
  }
}

@media (max-width: 750px){

  .kalniyojanServices,
  .magzineContainer > .magzine{
    display: flex;
    flex-direction: column;
  }

  .servicesDetailsContainer > .servicesDetailsContainerContent {
    display: flex;
    flex-direction: column;
  }

  .servicesDetailsContainerContent > .patrikaDetail,
  .servicesDetailsContainerContent > .softwareDetails,
  .servicesDetailsContainerContent > .TeachingDetails,
  .servicesDetailsContainerContent > .counsellingDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
  }

}

@media(max-width:540px){

  .magzine > .magzineSubscriptionInfo > .magzineContents,
  .magzine > .magzineSubscriptionInfo > .bankInfo{
    font-size: 20px;
  }

  .magzineContainer > .magzine{
    height: 900px;
  }

}

@media(max-width:390px){

  .magzineContainer > .magzine{
    height: 800px;
  }

  .magzineContainer > .magzine > .magzineSubscriptionInfo > .magzineYearlyRate{
    display: flex;
    flex-direction: column;
    width: 180px;
    height: fit-content;
    border-radius: 40px;
    font-size: 14px;
  }

  .magzineContainer > .magzine > .kalniyojanMagzineHeaderBrandYearCreator > .header{
    font-size: 32px;
  }

  .magzineContainer > .magzine > .kalniyojanMagzineHeaderBrandYearCreator > .kalniyojanMagzinePublishDate,
  .magzine > .kalniyojanMagzineHeaderBrandYearCreator > .magzineNameAndCreator > .Creator{
    font-size: 18px;
  }
}

@media(max-width: 360px){
  .magzineContainer > .magzine{
    height: 900px;
  }
}

@media(max-width: 320px){
  .servicesDetailsContainerContent > .patrikaDetail > .patrikaVariantLang > .langList,
  .softwareDetails > .softwareVariantLang > .langList{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .servicesDetailsContainerContent > .TeachingDetails > .teachingVariants,
  .servicesDetailsContainerContent > .softwareDetails .softwareVariants,
  .servicesDetailsContainerContent > .patrikaDetail .patrikaVariants,
  .servicesDetailsContainerContent > .counsellingDetails .counsellingVariants{
    font-size: 18px;
    align-items: center;
  }
}

@media(max-width: 285px){
  .servicesDetailsContainerContent > .softwareDetails{
    height: 700px;
  }

  .servicesDetailsContainerContent > .TeachingDetails .teachingVariant{
    font-size: 16px;
  }
}

@media(max-width: 260px){

  .magzineContainer > .magzine{
    height: 1000px;
  }

  .servicesDetailsContainerContent > .patrikaDetail{
    height: 650px;
  }

}
