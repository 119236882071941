.kalniyojanDonationContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 266px);
}

.kalniyojanDonationContainer > .kalniyojanDonationHeader{
  text-align: center;
  font-family: iosevka;
  font-size: 44px;
}

.kalniyojanDonationContainer > .kalniyojanDonationDes{
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 8px;
  width: 800px;
  padding: 10px;
  font-family: iosevka;
  text-transform: capitalize;
  text-align: center;
}

.kalniyojanDonationDes > .descOne{
  margin-top: 1.5em;
  font-size: 23px;
}

.kalniyojanDonationDes > .kalniyojanDonationOptions{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
  justify-content: space-between;
  width: 100%;
}

.kalniyojanDonationOptions > .donationOptionBank{
  padding: 2em;
  border-right: 1px solid grey;
}

.kalniyojanDonationOptions > .donationOptionBank > p{
  font-size: 22px;
}

.kalniyojanDonationOptions > img{
  width: 300px;
  height: 300px;
  justify-self: center;
}

.kalniyojanDonationDes > .kalniyojanDonationTermCondition{
  font-size: 13px;
}

@media(max-width: 850px){
  .kalniyojanDonationContainer > .kalniyojanDonationDes{
    width: 90%;
  }
}

@media(max-width: 600px){
  .kalniyojanDonationDes > .kalniyojanDonationOptions{
    flex-direction: column;
  }

  .kalniyojanDonationOptions > .donationOptionBank{
    border: none;
    border-bottom: 1px solid grey;
    margin-bottom: 2.5em;
  }
}

@media(max-width: 335px){
  .kalniyojanDonationDes > .kalniyojanDonationOptions > img{
    width: 230px;
    height: 230px;
  }
}