.matchMakingContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 266px);
}

.matchMakingContainer .matchMakingContainerContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 97%;
}

.matchMakingContainerContent h2{
  text-align: center;
  font-size: 32px;
  font-family: "Rubik";
  font-weight: normal;
  margin: 1em 1em;
}

.matchMakingContainerContent .matchMakingErrorShower{
  display: none;
}

.matchMakingContainerContent .matchMakingPaymentErrorShower{
  display: none;
}

.matchMakingContainerContent .matchMakingErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.matchMakingContainerContent .matchMakingPaymentErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.matchMakingContainerContent .kalniyojanMatchMakingFeesIndicator{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(30, 221, 30, 0.281);
    font-family: "Amaranth";
    text-decoration: underline;
}

.kalniyojanMatchMakingFeesIndicator div{
  font-size: 21px;
}

.matchMakingContainerContent form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 97%;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.matchMakingContainerContent form #matchMakingRequesterDetails{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content:center;
  align-items:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
}

.matchMakingContainerContent form #requesterDetails{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
}

#matchMakingRequesterDetails #requesterInputs{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  width: 97%;
  padding: 1rem;
}

#requesterInputs .requesterNameRow{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:97%;
}

.requesterNameRow label{
  align-self: self-start;
}

.requesterNameRow label span{
  color:red;
}

.requesterNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
  width:97%;
}

#requesterInputs .requesterEmailRow{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}

.requesterEmailRow label{
  align-self: self-start;
}

.requesterEmailRow label span{
  color:red;
}

.requesterEmailRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
  width:97%;
}

#requesterInputs .requesterPhoneRow{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
}

.requesterPhoneRow label{
  align-self: self-start;
}

.requesterPhoneRow label span{
  color:red;
}

.requesterPhoneRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
  width:97%;
}

#matchMakingRequesterDetails .matchMakingPhoneFormatNote{
  font-size: 12px;
  color:red;
  align-self: flex-start;
  margin-left: 2em;
  margin-bottom: 1em;
}

.matchMakingContainerContent form #maleFemaleDetails{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 10px;
  align-items:center;
  margin-top: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  padding: 1em;
}

#maleFemaleDetails #maleDetails{
  grid-column: 1 / span 2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1em;
}

#maleDetails #maleNameHeader{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
}

#maleNameRow{
  display:flex;
  flex-direction:column;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}

#maleNameRow label{
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

#maleNameRow label span{
  color: red;
}

#maleNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

#maleDetails .maleBirthDateTimeHeader{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
  margin-top: 1.5em;
}

#maleDetails .maleBirthDateTimeRow{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}

.maleBirthDateTimeRow .maleBirthDate{
  grid-column: 1/span 2;
  display: flex;
  flex-direction: column;
}

.maleBirthDate label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.maleBirthDate label span{
  color: red;
}

.maleBirthDate input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.maleBirthDateTimeRow .maleBirthTime{
  grid-column: 3/span 2;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.maleBirthTime label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.maleBirthTime label span{
  color: red;
}

.maleBirthTime input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

#maleDetails .maleBirthPlaceHeader{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
  margin-top:1.5em;
}

#maleDetails .maleBirthPlaceAndCountry{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 10px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}

.maleBirthPlaceAndCountry .maleBirthPlaceRow{
  grid-column: 1/span 2;
  display: flex;
  flex-direction: column;
}

.maleBirthPlaceRow label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.maleBirthPlaceRow label span{
  color:red;
}

.maleBirthPlaceRow input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.maleBirthPlaceAndCountry .maleBirthCountryRow{
  grid-column: 3 / span 2;
}

.maleBirthCountryRow label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.maleBirthCountryRow label span{
  color: red;
}


.maleBirthCountryRow select{
  height: 40px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}


#maleFemaleDetails #femaleDetails{
  grid-column: 3 / span 2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1em;
}

#femaleDetails #femaleNameHeader{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
}

#femaleNameRow{
  display:flex;
  flex-direction:column;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}

#femaleNameRow label{
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

#femaleNameRow label span{
  color: red;
}

#femaleNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

#femaleDetails .femaleBirthDateTimeHeader{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
  margin-top: 1.5em;
}

#femaleDetails .femaleBirthDateTimeRow{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}

.femaleBirthDateTimeRow .femaleBirthDate{
  grid-column: 1/span 2;
  display: flex;
  flex-direction: column;
}

.femaleBirthDate label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.femaleBirthDate label span{
  color: red;
}

.femaleBirthDate input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.femaleBirthDateTimeRow .femaleBirthTime{
  grid-column: 3/span 2;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.femaleBirthTime label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.femaleBirthTime label span{
  color: red;
}

.femaleBirthTime input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

#femaleDetails .femaleBirthPlaceHeader{
  align-self:flex-start;
  font-size: 16px;
  font-family: "Amaranth";
  margin-top:1.5em;
}

#femaleDetails .femaleBirthPlaceAndCountry{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 10px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}

.femaleBirthPlaceAndCountry .femaleBirthPlaceRow{
  grid-column: 1/span 2;
  display: flex;
  flex-direction: column;
}

.femaleBirthPlaceRow label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.femaleBirthPlaceRow label span{
  color:red;
}

.femaleBirthPlaceRow input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.femaleBirthPlaceAndCountry .femaleBirthCountryRow{
  grid-column: 3 / span 2;
}

.femaleBirthCountryRow label{
  align-self: flex-start;
  font-family: "Amaranth";
  font-size: 16px;
}

.femaleBirthCountryRow label span{
  color: red;
}


.femaleBirthCountryRow select{
  height: 40px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.matchMakingContainerContent form .matchMakingSubmitBtn{
  display: flex;
  flex-direction: column;
  margin-top: 1cm;
}

.matchMakingSubmitBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.matchMakingSubmitBtn > button:hover{
    cursor: pointer;
    transform: scale(.9);
}

.matchMakingContainer .matchMakingPaymentOptionsWindow{
  display: none;
  transform: translateX(-100%);
  z-index: 0;
}

.matchMakingContainer .matchMakingPaymentOptionsWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #fff;
}

.matchMakingPaymentOptionsWindowSelected div{
    width: 300px;
    height: fit-content;
}

.matchMakingPaymentOptionsWindowSelected div p{
  font-family: "Rubik";
  font-size: 30px;
}

.matchMakingPaymentOptionsWindowSelected div button{
    width: inherit;
    font-family: "Poppins";
    padding: 10px;
    font-size: 16px;
    background-color: #ACDDB8;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.matchMakingPaymentOptionsWindowSelected div button:hover{
  cursor: pointer;
}


.matchMakingContainer .matchMakingPaymentWindow{
  display: none;
  transform: translateX(-100%);
}

.matchMakingContainer .matchMakingPaymentWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    height: 100%;
}

@media(max-width: 950px){
  .requesterNameRow input{
    width: 80%;
    align-self: flex-start;
  }
  .requesterEmailRow input{
    width: 80%;
    align-self: flex-start;
  }
  .requesterPhoneRow input{
    width: 80%;
    align-self: flex-start;
  }
}

@media(max-width: 800px){

  .matchMakingContainer .matchMakingPaymentOptionsWindowSelected{
    flex-direction: column;
  }

  .matchMakingContainer .matchMakingPaymentOptionsWindowSelected .kalniyojanMatchMakingPaymentOptionOne{
    margin-bottom: 10px;
  }

}

@media(max-width: 850px){
  .matchMakingContainerContent form{
    width: 92%;
  }

  #maleDetails .maleBirthPlaceAndCountry{
    display: flex;
    flex-direction: column;
  }
  #femaleDetails .femaleBirthPlaceAndCountry{
    display: flex;
    flex-direction: column;

  }
}

@media(max-width: 750px){
  .matchMakingContainer .matchMakingPaymentWindow{
    display: none;
    transform: translateY(-100%);
  }

  .matchMakingContainer .matchMakingPaymentWindowSelected{
    position: fixed;
    transform: translateY(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    height: 50%;
  }
}


@media(max-width: 650px){

  .matchMakingContainerContent .matchMakingErrorShowerSelected{
    width: 80%;
  }

  .matchMakingContainerContent .matchMakingPaymentErrorShowerSelected{
    width: 80%;
  }
}

@media(max-width:600px){
  .matchMakingContainerContent form #maleFemaleDetails{
    display: flex;
    flex-direction: column;
    width:92%;
  }

  #maleFemaleDetails #maleDetails{
    margin-bottom: 1em;
  }
}

@media(max-width:570px){
  #matchMakingRequesterDetails #requesterInputs{
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  #requesterInputs .requesterNameRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .requesterNameRow input{
    align-self: center;
    width: 100%;
  }
  #requesterInputs .requesterPhoneRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .requesterEmailRow input{
    align-self: center;
    width: 97%;
  }
  #requesterInputs .requesterPhoneRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .requesterPhoneRow input{
    align-self: center;
    width: 97%;
  }
}

@media(max-width: 450px){
  .matchMakingContainerContent form{
    width: 80%;
  }
}

@media(max-width: 380px){
  #maleFemaleDetails #maleDetails .maleBirthDateTimeRow{
    display: flex;
    flex-direction: column;
  }

  #maleFemaleDetails #femaleDetails .femaleBirthDateTimeRow{
    display: flex;
    flex-direction: column;
  }

  #maleFemaleDetails #maleDetails .maleBirthDateTimeRow .maleBirthDate input{
    width: 95%;
  }

  #maleFemaleDetails #femaleDetails .femaleBirthDateTimeRow .femaleBirthDate input{
    width: 95%;
  }
}

@media(max-width:350px){
  .requesterNameRow input{
    align-self: center;
    width: 90%;
  }
  .requesterEmailRow input{
    align-self: center;
    width: 90%;
  }
  .requesterPhoneRow input{
    align-self: center;
    width: 90%;
  }
}

@media(max-width:325px){
  #maleFemaleDetails #maleDetails, #maleFemaleDetails #femaleDetails{
    box-shadow: none;
  }
}