
.kalniyojanTermsAndConditionsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 266px);
}

.kalniyojanTermsAndConditionsContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.kalniyojanTermsAndConditionsContainerContent h2{
    font-size: 38px;
    font-family: "Rubik";
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
}

.kalniyojanTermsAndConditionsContainerContent > p{
    font-size: 20px;
    font-family: "Rubik";
    text-align: center;
    font-weight: 500;
    margin-bottom: 2em;
    text-transform: capitalize;
}

.kalniyojanTermsAndConditionsContainerContent .kalniyojanTermsExplaination > ol{
    display: flex;
    flex-direction: column;
}

.kalniyojanTermsExplaination > ol > li{
    font-size: 18px;
    font-family: "Poppins";
    text-align: left;
    margin-bottom: 1em;
    padding: 10px;
    width: 800px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.kalniyojanTermsExplaination > ol > li div:first-child{
    font-weight: 700;
    margin-bottom: 10px;
}

.kalniyojanTermsExplaination > ol > li div:nth-child(2){
    text-transform: capitalize;
}

.kalniyojanTermsExplaination > ol > .kalniyojanPermittedUseTerms ol li{
    text-align: left;
    padding: 10px;
}

@media (max-width: 900px){
    .kalniyojanTermsExplaination ol li{
        width: 90%;
    }
}

@media (max-width: 430px){
    .kalniyojanTermsExplaination ol li{
        width: 85%;
    }
}