.adminDashboardPatrikaTabContainer{
    display: none;
    z-index: 0;
    transform: translateX(-100%);
}

.adminDashboardPatrikaTabContainerSelected{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
    transform: translateX(0);
    transition: all .5s ease-in-out;
}

.adminDashboardPatrikaTabContainerSelected > .adminDashboardPatrikaTabContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.adminDashboardPatrikaTabContainerContent .adminPatrikaErrorShower{
    display: none;
}

.adminDashboardPatrikaTabContainerContent .adminPatrikaEmptyList{
    font-size: 26px;
    font-family: Rubik;
}

.adminDashboardPatrikaTabContainerContent .adminPatrikaErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adminDashboardPatrikaTabContainerContent .adminDashboardPatrikaTable thead .adminpatrikatableheaderrow{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Amaranth";
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.adminDashboardPatrikaTabContainerContent table thead tr td{
    padding: 2px;
}

.adminDashboardPatrikaTabContainerContent table tbody{
    align-items: center;
    font-size: 15px;
    font-family: "Amaranth";
}

.adminDashboardPatrikaTabContainerContent table tbody tr td{
    padding: 10px;
    text-align: center;
}

.adminDashboardPatrikaTabContainerContent tbody tr td .patrikaSubmitted{
    border: 1px solid gray;
    border-radius: 8px;
}

.adminDashboardPatrikaTabContainerContent tbody tr td .patrikaSubmitted img{
    width: 35px;
    height: 25px;
}

.adminDashboardPatrikaTabContainerContent tbody tr td .patrikaSubmitted:hover{
    cursor: pointer;
}