.kalniyojanAboutContainer{
    display: flex;
    flex-direction: column;
    margin: 15px;
    font-family: "Rubik";
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 233px);
}

.kalniyojanAboutContainer sub{
    vertical-align:top;
    font-size: 18px;
}

.kalniyojanAboutHeader{
    text-align: center;
    font-size: 42px;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
}

.kalniyojanAboutBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1000px;
}

.kalniyojanAboutBody > p{
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 400;
    word-spacing: 2px;
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    background-color: #FFCB08;
    color: #065598;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.kalniyojanAboutBody > p > span{
    text-transform: uppercase;
}

.kalniyojanAboutBody > p > br{
    margin-top: 10px;
}

@media(max-width:340px){
    .kalniyojanAboutBody > p{
        font-size: 28px;
    }
}

@media(max-width: 1025px){
    .kalniyojanAboutBody{
        width: 98%;
    }
}