.adminAdviceContainer{
    display: none;
    transform: translateX(-100%);
    z-index: 0;
}

.adminAdviceContainerSelected{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
    transform: translateX(0);
    transition: all .5s ease-in-out;
}


.adminAdviceContainerSelected .adminAdviceContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
}

.adminAdviceContainerContent .adminAdviceErrorShower{
    display: none;
}

.adminAdviceContainerContent .adminAdviceErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adminAdviceContainerContent table thead tr{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Amaranth";
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.adminAdviceContainerContent table thead tr td{
    padding: 2px;
}

.adminAdviceContainerContent table tbody{
    align-items: center;
    font-size: 15px;
    font-family: "Amaranth";
}

.adminAdviceContainerContent table tbody tr td{
    padding: 2px;
    text-align: center;
}

.adminAdviceContainerContent table tbody tr .adminAdviceAction button{
    background-color: #fff;
    border: none;
}

.adminAdviceContainerContent table tbody tr .adminAdviceAction img{
    width: 35px;
    height: 25px;
}

.adminAdviceContainerContent table tbody tr .adminAdviceAction button:hover{
    cursor: pointer;
}

.adminAdviceContainerContent .adminEmptyAdvice{
    font-size: 30px;
}

@media(max-width: 725px){
    .adminAdviceContainerContent table thead tr .tableHeadPhoneCcTd{
        display: none;
    }

    .adminAdviceContainerContent table tbody tr .tableBodyPhoneCcTd{
        display: none;
    }
}

@media(max-width: 675px){
    table thead tr .tableHeadActionTd,
    table tbody tr .adminAdviceAction{
        display: none;
    }
}

@media(max-width: 620px){
    table thead tr .tableHeadEmailTd,
    table thead tr .tableHeadPhoneTd,
    table tbody tr .tableBodyEmailTd,
    table tbody tr .tableBodyPhoneTd{
        display: none;
    }
}