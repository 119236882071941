
.articleSuccessfulDownloadContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 266px);
}

.articleSuccessfulDownloadContainer > .articleSuccessfulDownloadContainerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    margin-top: 2cm;
    text-align: center;
    margin-bottom: 2cm;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    background-color: #f9e076;
}

.articleSuccessfulDownloadContainerContent > .articleCommertialSupport{
    padding: 20px;
    font-size: 30px;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.articleSuccessfulDownloadContainerContent > .articleDownloadSupportQrImg{
    width: 200px;
    padding: 20px;
}

@media(max-width: 740px){

.articleSuccessfulDownloadContainer > .articleSuccessfulDownloadContainerContent{
    width: 600px;
}

}

@media(max-width: 640px){

.articleSuccessfulDownloadContainer > .articleSuccessfulDownloadContainerContent{
    width: 500px;
}

}

@media(max-width: 540px){

.articleSuccessfulDownloadContainer > .articleSuccessfulDownloadContainerContent{
    width: 400px;
}

}

@media(max-width: 440px){

.articleSuccessfulDownloadContainer > .articleSuccessfulDownloadContainerContent{
    width: 300px;
}

}

@media(max-width: 340px){

.articleSuccessfulDownloadContainer > .articleSuccessfulDownloadContainerContent{
    width: 220px;
}

}