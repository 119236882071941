.kalniyojanLoginPageContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 248.7953px);
}

.kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > h1{
    font-size: 28px;
    font-family: "Amaranth";
    margin-top: 2rem;
    text-align: center;
}

.kalniyojanLoginPageContainerContent > .loginPageErrorShower{
  display: none;
}

.kalniyojanLoginPageContainerContent > .loginPageErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanLoginPageContainerContent > form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  height: 250px;
  margin: auto;
}

.kalniyojanLoginPageContainerContent > form > .loginEmailRow{
  display: flex;
  flex-direction: column;
  width: 65%;
}

form > .loginEmailRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

form > .loginEmailRow > input{
  height: 25px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.kalniyojanLoginPageContainerContent > form > .loginPasswordRow{
  display: flex;
  flex-direction: column;
  width: 65%;
}

form > .loginPasswordRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

form > .loginPasswordRow > input{
  height: 25px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.loginBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
  margin-top: 10px;
}

.loginBtn > button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 835px){
  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > .loginPageErrorShowerSelected{
    width: 600px;
  }
}

@media(max-width: 650px){
  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > .loginPageErrorShowerSelected{
    width: 400px;
  }
}

@media(max-width: 450px){
  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > .loginPageErrorShowerSelected{
    width: 290px;
  }
}

@media(max-width: 340px){
  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > .loginPageErrorShowerSelected{
    width: 250px;
  }
}

@media(max-width: 430px){
  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent{
    margin-bottom: 2rem;
  }

  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > form{
    width: 300px;
  }

}

@media(max-width: 330px){
  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > form{
    width: 230px;
  }

  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > form > .loginBtn{
    width: 75%;
    display: flex;
    justify-content: center;
  }

  .kalniyojanLoginPageContainer > .kalniyojanLoginPageContainerContent > form > .loginBtn > button{
    width: 60%;
  }
}