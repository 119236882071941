#JanmapatrikatypesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 266px);
}

#JanmapatrikatypesContainer #JanmapatrikatypesContainerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 87%;
}

#JanmapatrikatypesContainerContent h1{
    font-size: 32px;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
}

#JanmapatrikatypesContainerContent div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#JanmapatrikatypesContainerContent div p{
    font-size: 24px;
    font-family: "Poppins";
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
}

#JanmapatrikatypesContainerContent div dl{
    font-size: 20px;
    font-family: "Poppins";
}

#JanmapatrikatypesContainerContent div dl li{
    padding: 1em;
    list-style: none;
    text-transform: capitalize;
}

#JanmapatrikatypesContainerContent div dl li span{
    font-size: 22px;
    font-weight: bold;
    margin-right: 1em;
    text-transform: capitalize;
}