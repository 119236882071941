
.adviceContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  padding: 10px;
  min-height: calc(100vh - 266px);
}

.adviceContainer > .adviceContainerContent{
    display: flex;
    flex-direction: column;
    width: 97%;
    justify-content: center;
    align-items: center;
}

.adviceContainerContent h2{
    text-align: center;
    font-size: 32px;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
}

.adviceContainerContent > .adviceFormErrorShower{
    display: none;
}

.adviceContainerContent > .advicePaymentErrorShower{
    display: none;
}

.adviceContainerContent > .adviceFormErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adviceContainerContent .advicePaymentErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adviceContainerContent .kalniyojanAdviceFeesIndicator{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(30, 221, 30, 0.281);
    font-family: "Amaranth";
    text-decoration: underline;
}

.kalniyojanAdviceFeesIndicator div{
    font-size: 21px;

}

.adviceContainerContent > form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 2rem;
}

.adviceNameRow{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.adviceNameRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
}

.adviceNameRow > label > span{
    color: red;
}

.adviceNameRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adviceEmailRow{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.adviceEmailRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
}

.adviceEmailRow > label > span{
    color: red;
}

.adviceEmailRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.advicePhoneRow{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.advicePhoneRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
}

.advicePhoneRow > label > span{
    color: red;
}

.advicePhoneRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.advicePhoneRow > .advicePhoneFormatNote{
    font-size:12px;
    color: red;
    margin-left: 10px;
    text-transform: capitalize;

}

.adviceContainerContent > form > .adviceBirthDateHeader{
    align-self: flex-start;
    margin-top: 15px;
    font-family: "Amaranth";
    font-size: large;
}

.adviceClientBirthDateRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.adviceClientBirthDateRow > div > label > span{
    color: red;
}

.adviceClientBirthDateRow > .clientBirthYearRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.clientBirthYearRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.clientBirthYearRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adviceClientBirthDateRow > .clientBirthMonthRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.clientBirthMonthRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.clientBirthMonthRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adviceClientBirthDateRow > .clientBirthDayRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.clientBirthDayRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.clientBirthDayRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adviceContainerContent > form > .adviceBirthTimeHeader{
    align-self: flex-start;
    margin-top: 15px;
    font-family: "Amaranth";
    font-size: large;
}

.adviceContainerContent > form > .adviceClientBirthTimeRow{
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 97%;
}

.adviceClientBirthTimeRow > .birthTimeHourMinuteRow{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.birthTimeHourMinuteRow > .clientBirthTimeHourRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.clientBirthTimeHourRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.clientBirthTimeHourRow > label > span{
    color: red;
}

.clientBirthTimeHourRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.birthTimeHourMinuteRow > .clientBirthTimeMinuteRow{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.clientBirthTimeMinuteRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.clientBirthTimeMinuteRow > label > span{
    color: red;
}

.clientBirthTimeMinuteRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adviceClientBirthTimeRow > .adviceBirthTimeFormat{
    margin-left: 10px;
    font-size: 12px;
    color: red;
}


.adviceContainerContent > form > .adviceBirthPlaceHeader{
    align-self: flex-start;
    margin-top: 15px;
    font-family: "Amaranth";
    font-size: large;
}

.adviceContainerContent > form > .adviceBirthPlaceAndCountry{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 98%;
}

.adviceBirthPlaceAndCountry > .adviceClientBirthPlaceRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.adviceClientBirthPlaceRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.adviceClientBirthPlaceRow > label > span{
    color: red;
}

.adviceClientBirthPlaceRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.adviceBirthPlaceAndCountry > .adviceClientBirthCountryRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.adviceClientBirthCountryRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.adviceClientBirthCountryRow > label > span{
    color: red;
}

.adviceClientBirthCountryRow > select{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.adviceContainerContent > form > .clientQuestionRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 98%;
}

.clientQuestionRow > label{
    font-size: 16px;
    font-family: "Amaranth";
    font-weight: 100;
    align-self: flex-start;
}

.clientQuestionRow > label > span{
    color: red;
}

.clientQuestionRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.adviceSubmitBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.adviceSubmitBtn > button:hover{
    cursor: pointer;
    transform: scale(.9);
}

.adviceContainer .advicePaymentOptionsWindow{
    display: none;
    transform: translateX(-100%);
    z-index: 0;
}

.adviceContainer .advicePaymentOptionsWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #fff;
}

.advicePaymentOptionsWindowSelected div{
    width: 300px;
    height: fit-content;
}

.advicePaymentOptionsWindowSelected div p{
    font-family: "Rubik";
    font-size: 30px;
}

.advicePaymentOptionsWindowSelected div button{
    width: inherit;
    font-family: "Poppins";
    padding: 10px;
    font-size: 16px;
    background-color: #ACDDB8;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.advicePaymentOptionsWindowSelected div button:hover{
    cursor: pointer;
}

.advicePaymentWindow{
    display: none;
    transform: translateX(-100%);
}

.advicePaymentWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    height: 100%;
}

@media(max-width: 850px){

    .advicePaymentWindow{
        display: none;
        transform: translateY(-100%);
    }

    .advicePaymentWindowSelected{
        position: fixed;
        transform: translateY(0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: #fff;
        height: 50%;
    }
}

@media(max-width: 750px){

    .adviceContainer .advicePaymentOptionsWindowSelected{
        display: flex;
        flex-direction: column;
    }

    .adviceContainer .advicePaymentOptionsWindowSelected .kalniyojanPaymentOptionOne{
        margin-bottom: 10px;
    }
}

@media(max-width: 700px){
    .adviceContainerContent > form{
        width: 500px;
    }
    
    .adviceContainerContent > .advicePaymentAndQrInfo{
        width: 500px;
    }

    .adviceContainerContent > .adviceFormErrorShowerSelected{
        width: 500px;
    }

    .adviceContainerContent .advicePaymentErrorShowerSelected{
        width: 500px;
    }
}

@media(max-width: 590px){
    .adviceContainerContent > form{
        width: 390px;
    }
    
    .adviceContainerContent > .advicePaymentAndQrInfo{
        width: 390px;
    }

    .adviceContainerContent > .adviceFormErrorShowerSelected{
        width: 390px;
    }

    .adviceClientBirthDateRow{
        flex-direction: column;
        width: 97%;
    }

    .adviceContainerContent > .advicePaymentAndQrInfo{
        flex-direction: column;
    }
    
    .advicePaymentAndQrInfo > .advicePaymentInfo {
        margin-bottom: 15px;
    }

    .adviceContainerContent > form > .adviceBirthPlaceAndCountry{
        flex-direction: column;
    }

    .adviceContainerContent .advicePaymentErrorShowerSelected{
        width: 390px;
    }
}

@media(max-width: 490px){
    .adviceContainerContent > form{
        width: 290px;
    }
    
    .adviceContainerContent > .advicePaymentAndQrInfo{
        width: 290px;
    }

    .adviceContainerContent > .adviceFormErrorShowerSelected{
        width: 290px;
    }

    .adviceContainerContent .advicePaymentErrorShowerSelected{
        width: 290px;
    }

    .adviceClientBirthDateRow{
        flex-direction: column;
        width: 97%;
    }

    .adviceContainerContent > form > .adviceClientBirthTimeRow > .birthTimeHourMinuteRow{
        flex-direction: column;
    }

    .adviceContainerContent > .advicePaymentAndQrInfo{
        flex-direction: column;
    }
    
    .advicePaymentAndQrInfo > .advicePaymentInfo {
        margin-bottom: 15px;
    }
}

@media(max-width: 390px){
    .adviceContainerContent > form{
        width: 250px;
    }
    
    .adviceContainerContent > .advicePaymentAndQrInfo{
        width: 250px;
    }

    .adviceContainerContent > .adviceFormErrorShowerSelected {
        width: 250px;
    }

    .adviceContainerContent .advicePaymentErrorShowerSelected{
        width: 250px;
    }

    .adviceClientBirthDateRow{
        flex-direction: column;
        width: 97%;
    }

    .adviceContainerContent > form > .adviceClientBirthTimeRow > .birthTimeHourMinuteRow{
        flex-direction: column;
    }

    .adviceContainerContent > .advicePaymentAndQrInfo{
        flex-direction: column;
    }
    
    .advicePaymentAndQrInfo > .advicePaymentInfo {
        margin-bottom: 15px;
    }
}

@media(max-width: 340px){
    .adviceContainerContent > form{
        width: 200px;
    }
    
    .adviceContainerContent > .advicePaymentAndQrInfo{
        width: 200px;
    }

    .adviceClientBirthDateRow{
        flex-direction: column;
        width: 97%;
    }

    .adviceContainerContent > form > .adviceClientBirthTimeRow > .birthTimeHourMinuteRow{
        flex-direction: column;
    }

    .adviceContainerContent > .advicePaymentAndQrInfo{
        flex-direction: column;
    }
    
    .advicePaymentAndQrInfo > .advicePaymentInfo {
        margin-bottom: 15px;
    }
}
