.visible{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    transform: translateY(0);
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.visible > .closeBtn{
    position: fixed;
    right: 1rem;
    top: 1rem;
    border: 1px dashed black;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.visible > .closeBtn:hover{
    transform: scale(1.1);
    border: 1px solid black;
}

.visible > .closeBtn > img{
    width: 25px;
    height: 25px;
}

.visible > .closeBtn > img:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.novisible{
    transform: translateY(-100%);
    display: none;
    transition: all 0.5s ease-out;
}

.visible > .sidebarLinks{
    display: flex;
    flex-direction: column;
    list-style: none;
    overflow-y: scroll;
}

.sidebarLinks > div{
    padding: 10px;
    text-align: center;
    margin: 10px;
    font-size: 36px;
    color: black;
    justify-content: center;
    align-items: center;
    border: 1px solid lightblue;
    border-radius: 15px;
    font-family: iosevka;
    width: 300px;
    text-transform: capitalize;
}

.sidebarLinks > div:hover{
    background-color: #262626;
    border: 2px solid lightblue;
    border-radius: 12px;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

@media(max-width:640px){

    .visible > .closeBtn > img{
        width: 35px;
        height: 35px;
    }

    .visible > .sidebarLinks > div{
        font-size: 40px;
    }
}

@media(max-width:560px){
    .visible > .sidebarLinks > div{
        font-size: 36px;
        /* width: 230px; */
    }
}

@media(max-width:470px){
    .visible > .sidebarLinks > div{
        font-size: 32px;
    }
}

@media(max-width:400px){
    .visible > .sidebarLinks > div{
        font-size: 30px;
    }
}
@media(max-width:340px){
    .sidebarLinks > div{
        width: 250px;
    }
}

@media(max-width:310px){
    .visible > .sidebarLinks > div{
        font-size: 28px;
    }
}

@media(max-width:290px){
    .sidebarLinks > div{
        width: 200px;
    }
}