
.kalniyojanRefundHelpContainer{
  min-height: calc(100vh - 266px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kalniyojanRefundHelpContainer .kalniyojanRefundHelpContainerContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.kalniyojanRefundHelpContainerContent h2{
  font-family: "Rubik";
  font-size: 42px;
  text-align: center;
}

.kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationErrorShower{
  display: none;
}

.kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationNote{
  width: 800px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  font-family: "Poppins";
  font-size: 20px;
  margin-bottom: 1em;
  text-transform: capitalize;
  border-radius: 10px;
}

.kalniyojanRefundHelpContainerContent form{
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  width: 600px;
  display: flex;
  flex-direction: column;
}

.kalniyojanRefundHelpContainerContent form .RCFEmailContainer,
.kalniyojanRefundHelpContainerContent form .RCFPhoneContainer,
.kalniyojanRefundHelpContainerContent form .RCFProductCodeContainer,
.kalniyojanRefundHelpContainerContent form .RCFRemarkContainer{
  display: flex;
  flex-direction: column;
}

.RCFEmailContainer label span,
.RCFPhoneContainer label span,
.RCFProductCodeContainer label span,
.RCFRemarkContainer label span{
  color: red;
}

.RCFEmailContainer label,
.RCFPhoneContainer label,
.RCFProductCodeContainer label,
.RCFRemarkContainer label{
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
}

.RCFEmailContainer input,
.RCFPhoneContainer input,
.RCFProductCodeContainer input,
.RCFRemarkContainer input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanRefundHelpContainerContent form button{
  width: 100px;
  align-self: center;
  margin-top: 1em;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  background-color: lightgreen;
  font-family: "Amaranth";
}

.kalniyojanRefundHelpContainerContent form button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 860px){

  .kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationNote{
    width: 600px;
  }
}

@media(max-width: 650px){
  .kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationNote,
  .kalniyojanRefundHelpContainerContent form,
  .kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationErrorShowerSelected{
    width: 90%;
  }
}

@media(max-width: 400px){
  .kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationNote,
  .kalniyojanRefundHelpContainerContent form,
  .kalniyojanRefundHelpContainerContent .kalniyojanRefundCancellationErrorShowerSelected{
    width: 80%;
  }
}