.adviceSuccessfulContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: calc(100vh - 266px);
}

.adviceSuccessfulContainer > .adviceSuccessfulContainerContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adviceSuccessfulContainerContent > .adviceSuccessfulMessage{
  text-transform: capitalize;
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 2rem;
  font-size: 26px;
  margin-top: 2cm;
  text-align: center;
  margin-bottom: 50px;
}

.adviceSuccessfulMessage img{
  width: 200px;
}

.adviceSuccessfulMessage .serviceUseThanksMessage{
  margin-top: 25px;
  font-size: 28px;
}

.adviceSuccessfulContainerContent > .adviceSuccessfulNavigationBtn{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 200px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
}

.adviceSuccessfulNavigationBtn > .adviceSuccessfulHomeNavigationBtn{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.adviceSuccessfulNavigationBtn > .adviceSuccessfulHomeNavigationBtn:hover{
  cursor: pointer;
}

.adviceSuccessfulNavigationBtn > .adviceSuccessfulServicesNavigationBtn{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.adviceSuccessfulNavigationBtn > .adviceSuccessfulServicesNavigationBtn:hover{
  cursor: pointer;
}

@media(max-width: 700px){
  .adviceSuccessfulContainerContent > .adviceSuccessfulMessage{
    width: 500px;
  }
}

@media(max-width: 600px){
  .adviceSuccessfulContainerContent > .adviceSuccessfulMessage{
    width: 400px;
  }
}

@media(max-width: 500px){
  .adviceSuccessfulContainerContent > .adviceSuccessfulMessage{
    width: 300px;
  }
}

@media(max-width: 400px){
  .adviceSuccessfulContainerContent > .adviceSuccessfulMessage{
    width: 250px;
  }
}

@media(max-width: 350px){
  .adviceSuccessfulContainerContent > .adviceSuccessfulMessage{
    width: 200px;
  }

  .adviceSuccessfulContainerContent .adviceSuccessfulNavigationBtn{
    padding: 10px;
  }
}