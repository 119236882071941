.kalniyojanMuhurtaContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 266px);
}

.kalniyojanMuhurtaContainer .kalniyojanMuhurtaContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
}

.kalniyojanMuhurtaContainerContent h1{
    font-size: 32px;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
    text-align: center;
}

.kalniyojanMuhurtaContainerContent .muhurtaErrorShower{
    display: none;
}

.kalniyojanMuhurtaContainerContent .muhurtaErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
  margin-bottom: 1em;
  text-align: center;
}

.kalniyojanMuhurtaContainerContent .muhurtaFeeIndicator{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  font-size: 22px;
  margin-bottom: 1rem;
  background-color: rgba(30, 221, 30, 0.281);
  font-family: "Amaranth";
  text-decoration: underline;
}

.kalniyojanMuhurtaContainerContent .muhurtaPaymentErrorShower{
    display: none;
}

.kalniyojanMuhurtaContainerContent .muhurtaPaymentErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
  text-align: center;
}

.kalniyojanMuhurtaContainerContent form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 900px;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

form .muhurtaClientDetailsRow{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 10px;
    row-gap: 1em;
    width: 100%;
}

.muhurtaClientDetailsRow .muhurtaClientNameRow{
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
}

.muhurtaClientNameRow label span{
    color: red;
}

.muhurtaClientNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.muhurtaClientDetailsRow .muhurtaClientEmailRow{
    grid-column: 3 / span 2;
    display: flex;
    flex-direction: column;
}

.muhurtaClientEmailRow label span{
    color: red;
}

.muhurtaClientEmailRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.muhurtaClientDetailsRow .muhurtaClientPhoneRow{
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
}

.muhurtaClientPhoneRow label span{
    color: red;
}

.muhurtaClientPhoneRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.muhurtaClientPhoneRow p{
  font-size: 12px;
  text-transform: capitalize;
  color: red;
  margin-top: 0;
}

.muhurtaClientDetailsRow .muhurtaForRow{
    grid-column: 3 / span 2;
    display: flex;
    flex-direction: column;
}

.muhurtaForRow label span{
    color: red;
}

.muhurtaForRow select{
  height:40px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.kalniyojanMuhurtaContainerContent form .oneForAllMuhurtaFormField{
    display: none;
    transform: translateY(-100vh);
}

.kalniyojanMuhurtaContainerContent form .oneForAllMuhurtaFormFields{
    grid-column: 1 / span 4;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 2em;
    padding: 1em;
    border-radius: 15px;
    transform: translateY(0);
    transition: 500ms all ease-in-out;
    width: 97%;
}

.oneForAllMuhurtaFormFields > h3{
    text-align: center;
}

.oneForAllMuhurtaFormFields .oneForAllMuhurtaRow{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.oneForAllMuhurtaRow .oneForAllMuhurtaNameRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
}

.oneForAllMuhurtaNameRow label{
    font-family: "Amaranth";
}

.oneForAllMuhurtaNameRow label span{
    color:red;
}

.oneForAllMuhurtaNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.oneForAllMuhurtaRow .oneForAllMuhurtaBirthDateRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.oneForAllMuhurtaBirthDateRow label{
    font-family: "Amaranth";
}

.oneForAllMuhurtaBirthDateRow label span{
    color: red;
}

.oneForAllMuhurtaBirthDateRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.oneForAllMuhurtaRow .oneForAllMuhurtaBirthTimeRow{
    display: flex;
    flex-direction: column;
    grid-column: 3 / span 2;
}

.oneForAllMuhurtaBirthTimeRow label{
    font-family: "Amaranth";
}

.oneForAllMuhurtaBirthTimeRow label span{
    color:red;
}

.oneForAllMuhurtaBirthTimeRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.oneForAllMuhurtaRow .oneForAllMuhurtaBirthPlaceRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.oneForAllMuhurtaBirthPlaceRow label{
    font-family: "Amaranth";
}

.oneForAllMuhurtaBirthPlaceRow label span{
    color:red;
}

.oneForAllMuhurtaBirthPlaceRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.oneForAllMuhurtaRow .oneForAllMuhurtaBirthCountryRow{
    display: flex;
    flex-direction: column;
    grid-column: 3 / span 2;
}

.oneForAllMuhurtaBirthCountryRow label{
    font-family: "Amaranth";
}

.oneForAllMuhurtaBirthCountryRow label span{
    color:red;
}

.oneForAllMuhurtaBirthCountryRow select{
  height: 40px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

form .kalniyojanMuhurtaGarbhaDhanField{
    display: none;
}

form .kalniyojanMuhurtaGarbhaDhanFields{
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 2em;
    padding: 1em;
    border-radius: 15px;
    width: 97%;
}

.kalniyojanMuhurtaGarbhaDhanFields h3{
    text-align: center;
}

.kalniyojanMuhurtaGarbhaDhanFields .garbhadhanMaleFemaleDetailsRow{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaFemaleNameRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
}

.garbhadhanMuhurtaFemaleNameRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaFemaleNameRow label span{
    color: red;
}

.garbhadhanMuhurtaFemaleNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaMaleNameRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
}

.garbhadhanMuhurtaMaleNameRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaMaleNameRow label span{
    color: red;
}

.garbhadhanMuhurtaMaleNameRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}


.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaFemaleBirthDateRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.garbhadhanMuhurtaFemaleBirthDateRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaFemaleBirthDateRow label span{
    color: red;
}

.garbhadhanMuhurtaFemaleBirthDateRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaMaleBirthDateRow{
    display: flex;
    flex-direction: column;
    grid-column: 3 / span 2;
}

.garbhadhanMuhurtaMaleBirthDateRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaMaleBirthDateRow label span{
    color: red;
}

.garbhadhanMuhurtaMaleBirthDateRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaFemaleBirthTimeRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.garbhadhanMuhurtaFemaleBirthTimeRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaFemaleBirthTimeRow label span{
    color: red;
}

.garbhadhanMuhurtaFemaleBirthTimeRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaMaleBirthTimeRow{
    display: flex;
    flex-direction: column;
    grid-column: 3 / span 2;
}

.garbhadhanMuhurtaMaleBirthTimeRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaMaleBirthTimeRow label span{
    color: red;
}

.garbhadhanMuhurtaMaleBirthTimeRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaFemaleBirthPlaceRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.garbhadhanMuhurtaFemaleBirthPlaceRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaFemaleBirthPlaceRow label span{
    color:red;
}

.garbhadhanMuhurtaFemaleBirthPlaceRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaMaleBirthPlaceRow{
    display: flex;
    flex-direction: column;
    grid-column: 3 / span 2;
}

.garbhadhanMuhurtaMaleBirthPlaceRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaMaleBirthPlaceRow label span{
    color:red;
}

.garbhadhanMuhurtaMaleBirthPlaceRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaFemaleBirthCountryRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
}

.garbhadhanMuhurtaFemaleBirthCountryRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaFemaleBirthCountryRow label span{
    color: red;
}

.garbhadhanMuhurtaFemaleBirthCountryRow select{
  height:40px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.garbhadhanMaleFemaleDetailsRow .garbhadhanMuhurtaMaleBirthCountryRow{
    display: flex;
    flex-direction: column;
    grid-column: 3 / span 2;
}

.garbhadhanMuhurtaMaleBirthCountryRow label{
    font-family: "Amaranth";
}

.garbhadhanMuhurtaMaleBirthCountryRow label span{
    color: red;
}

.garbhadhanMuhurtaMaleBirthCountryRow select{
  height:40px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

form .kalniyojanMuhurtaSubmitBtn button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
    margin-top: 2em;
}

form .kalniyojanMuhurtaSubmitBtn button:hover{
    cursor: pointer;
    transform: scale(.9);
}

.kalniyojanMuhurtaContainer .muhurtaPaymentOptionsWindow{
  display: none;
  transform: translateX(-100%);
  z-index: 0;
}

.kalniyojanMuhurtaContainer .muhurtaPaymentOptionsWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #fff;
}

.muhurtaPaymentOptionsWindowSelected div{
    width: 300px;
    height: fit-content;
}

.muhurtaPaymentOptionsWindowSelected div p{
  font-family: "Rubik";
  font-size: 30px;
}

.muhurtaPaymentOptionsWindowSelected div button{
    width: inherit;
    font-family: "Poppins";
    padding: 10px;
    font-size: 16px;
    background-color: #ACDDB8;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.muhurtaPaymentOptionsWindowSelected div button:hover{
    cursor: pointer;
}
    
.kalniyojanMuhurtaContainer .muhurtaPaymentWindow{
    display: none;
    transform: translateX(-100%);
}

.kalniyojanMuhurtaContainer .muhurtaPaymentWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    height: 100%;
}

@media(max-width: 960px){
    .kalniyojanMuhurtaContainerContent form{
        width: 90%;
    }
}

@media(max-width: 800px){

    .kalniyojanMuhurtaContainer .muhurtaPaymentOptionsWindowSelected{
        flex-direction: column;
    }

    .kalniyojanMuhurtaContainer .muhurtaPaymentOptionsWindowSelected .kalniyojanMuhurtaPaymentOptionOne{
        margin-bottom: 10px;
    }
}

@media(max-width: 650px){
    .kalniyojanMuhurtaContainerContent .muhurtaErrorShowerSelected,
    .kalniyojanMuhurtaContainerContent .muhurtaPaymentErrorShowerSelected{
        width: 90%;
    }

    .kalniyojanMuhurtaContainer .muhurtaPaymentWindow{
        display: none;
        transform: translateY(-100%);
    }

    .kalniyojanMuhurtaContainer .muhurtaPaymentWindowSelected{
        position: fixed;
        transform: translateY(0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: #fff;
        height: 50%;
    }
}

@media(max-width: 330px){
    .kalniyojanMuhurtaContainerContent .muhurtaErrorShowerSelected,
    .kalniyojanMuhurtaContainerContent .muhurtaPaymentErrorShowerSelected{
        width: 80%;
    }

    .kalniyojanMuhurtaContainerContent form .kalniyojanMuhurtaGarbhaDhanFields .garbhadhanMaleFemaleDetailsRow{
        display: flex;
        flex-direction: column;
    }

    .kalniyojanMuhurtaContainerContent form .muhurtaClientDetailsRow{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .kalniyojanMuhurtaContainerContent form .muhurtaClientDetailsRow .muhurtaClientNameRow,
    .kalniyojanMuhurtaContainerContent form .muhurtaClientDetailsRow .muhurtaClientEmailRow,
    .kalniyojanMuhurtaContainerContent form .muhurtaClientDetailsRow .muhurtaClientPhoneRow,
    .kalniyojanMuhurtaContainerContent form .muhurtaClientDetailsRow .muhurtaForRow{
        width: 100%;
    }

    .kalniyojanMuhurtaContainerContent form .oneForAllMuhurtaFormFields .oneForAllMuhurtaRow{
        display: flex;
        flex-direction: column;
    }
}

@media(max-width: 315px){
    .kalniyojanMuhurtaContainerContent form{
        box-shadow: none; 
    }
}