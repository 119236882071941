.kalniyojanAdminMainPageImageContainer{
  display: none;
  position: relative;
  z-index: 0;
  transform: translateX(-100%);
}

.kalniyojanAdminMainPageImageContainerSelected{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  transform: translateX(0);
  transition: all .5s ease-in-out;
  margin-bottom: 2rem;
}

.kalniyojanAdminMainPageImageContainerSelected > .mainPageErrorShower{
  display: none;
}

.kalniyojanAdminMainPageImageContainerSelected > .mainPageErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanAdminMainPageImageContainerSelected > form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 15px;
  height: 300px;
  width: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.adminMainPageFrontImageRow{
  display: flex;
  flex-direction: column;
}

.adminMainPageFrontImageRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.adminMainPageFrontImageRow > input{
  height: 35px;
  margin-bottom: 10px;
  width: 100%;
}

.adminMainPageFrontBackImageRow{
  display: flex;
  flex-direction: column;
}

.adminMainPageFrontBackImageRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.adminMainPageFrontBackImageRow > input{
  height: 35px;
  margin-bottom: 10px;
  width: 100%;
}

.adminMainPageBackBackImageRow{
  display: flex;
  flex-direction: column;
}

.adminMainPageBackBackImageRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.adminMainPageBackBackImageRow > input{
  height: 35px;
  margin-bottom: 10px;
  width: 100%;
}

.kalniyojanAdminMainPageSubmitBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.kalniyojanAdminMainPageSubmitBtn > button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 850px){
  .kalniyojanAdminMainPageImageContainerSelected > .mainPageErrorShowerSelected{
    width: 500px;
  }
}

@media(max-width: 555px){
  .kalniyojanAdminMainPageImageContainerSelected > .mainPageErrorShowerSelected{
    width: 350px;
  }
}

@media(max-width: 400px){
  .kalniyojanAdminMainPageImageContainerSelected > .mainPageErrorShowerSelected{
    width: 316px;
  }
}

@media(max-width: 360px){
  .kalniyojanAdminMainPageImageContainerSelected > .mainPageErrorShowerSelected{
    width: 290px;
  }
}

@media(max-width: 680px){
  .kalniyojanAdminMainPageImageContainerSelected > form{
    width: 400px;
  }
}

@media(max-width: 485px){
  .kalniyojanAdminMainPageImageContainerSelected > form{
    width: 280px;
  }
}

@media(max-width: 365px){
  .kalniyojanAdminMainPageImageContainerSelected > form{
    width: 240px;
    padding: 1rem;
  }

  .kalniyojanAdminMainPageImageContainerSelected > form > .adminMainPageFrontImageRow > label{
    font-size: 14px;
  }

  .kalniyojanAdminMainPageImageContainerSelected > form > .adminMainPageFrontBackImageRow > label{
    font-size: 14px;
  }

  .kalniyojanAdminMainPageImageContainerSelected > form > .adminMainPageBackBackImageRow > label{
    font-size: 14px;
  }

  .kalniyojanAdminMainPageImageContainerSelected > form > .kalniyojanAdminMainPageSubmitBtn{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .kalniyojanAdminMainPageImageContainerSelected > form > .kalniyojanAdminMainPageSubmitBtn > button{
    width: 75%;
  }
}
