.adminDashboardMuhurtaTabShower{
    display: none;
    z-index: 0;
    transform: translateX(-100%);
}

.adminDashboardMuhurtaTabShowerSelected{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
    transform: translateX(0);
    transition: all .5s ease-in-out;
}

.adminDashboardMuhurtaTabShowerSelected .adminDashboardMuhurtaTabContainerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaErrorShower{
    display: none;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable thead tr{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Amaranth";
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable tbody{
    align-items: center;
    font-size: 16px;
    font-family: "Amaranth";
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable #adminMuhurtaTableBody tr:nth-child(1){
    text-transform: capitalize;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable tbody tr td{
    padding: 10px;
    text-align: center;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable tbody tr td button{
    border: 1px solid grey;
    border-radius: 8px;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable tbody tr td button img{
    width: 40px;
    height: 30px;
}

.adminDashboardMuhurtaTabContainerContent .adminMuhurtaTable tbody tr td button:hover{
    cursor: pointer;
}

.adminDashboardMuhurtaTabContainerContent .adminEmptyMuhurtaList{
    font-size: 26px;
    font-family: "Rubik";
}

