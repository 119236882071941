
.kalniyojanFeedbackContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 266px);
}

.kalniyojanFeedbackContainer .kalniyojanFeedbackContainerContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.kalniyojanFeedbackContainerContent h2{
  font-family: "Rubik";
  font-size: 42px;
}

.kalniyojanFeedbackContainerContent .kalniyojanFeedbackErrorShower{
  display: none;
}

.kalniyojanFeedbackContainerContent .kalniyojanFeedbackErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanFeedbackContainerContent .kalniyojanFeedbackSuccessShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: #77DD77;
  font-size: 16px;
}

.kalniyojanFeedbackContainerContent .kalniyojanFeedbackNote{
  width: 800px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  font-family: "Poppins";
  font-size: 20px;
  margin-bottom: 1em;
  text-transform: capitalize;
}

.kalniyojanFeedbackContainerContent form{
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  width: 600px;
  display: flex;
  flex-direction: column;
}

.kalniyojanFeedbackContainerContent form .kalniyojanFeedbackLabel{
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
}

.kalniyojanFeedbackContainerContent form > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanFeedbackContainerContent form .kalniyojanFeedbackLabel span,
.kalniyojanFeedbackContainerContent form .kalniyojanFeedbackMessage label span{
  color: red;
}

.kalniyojanFeedbackContainerContent form .kalniyojanFeedbackMessage{
  display: flex;
  flex-direction: column;
}

.kalniyojanFeedbackContainerContent form .kalniyojanFeedbackMessage label{
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
}

.kalniyojanFeedbackContainerContent form .kalniyojanFeedbackMessage input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanFeedbackContainerContent form button{
  width: 100px;
  align-self: center;
  margin-top: 1em;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  background-color: lightgreen;
  font-family: "Amaranth";
}

.kalniyojanFeedbackContainerContent form button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 860px){

  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackNote{
    width: 600px;
  }
}

@media(max-width: 650px){

  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackNote,
  .kalniyojanFeedbackContainerContent form,
  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackErrorShowerSelected,
  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackSuccessShowerSelected{
    width: 90%;
  }

}

@media(max-width: 400px){

  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackNote,
  .kalniyojanFeedbackContainerContent form,
  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackErrorShowerSelected,
  .kalniyojanFeedbackContainerContent .kalniyojanFeedbackSuccessShowerSelected{
    width: 80%;
  }
}