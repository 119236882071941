
.kalniyojanPrivacyContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    min-height: calc(100vh - 266px);
}

.kalniyojanPrivacyContainer .kalniyojanPrivacyContainerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kalniyojanPrivacyContainerContent > h2{
    font-size: 38px;
    font-family: "Rubik";
    font-weight: 500;
    text-align: center;
}

.kalniyojanPrivacyContainerContent > p{
    font-size: 20px;
    font-family: "Rubik";
    text-align: center;
    font-weight: 500;
    margin-bottom: 2em;
    text-transform: capitalize;
}

.kalniyojanPrivacyContainerContent .kalniyojanPolicyExplaination > ol{
    display: flex;
    flex-direction: column;
}

.kalniyojanPolicyExplaination > ol > li{
    font-size: 18px;
    font-family: "Poppins";
    text-align: left;
    margin-bottom: 1em;
    padding: 10px;
    width: 800px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.kalniyojanPolicyExplaination > ol > li div:first-child{
    font-weight: 700;
    margin-bottom: 10px;
}

.kalniyojanPolicyExplaination > ol > li div:nth-child(2){
    text-transform: capitalize;
}

@media (max-width: 900px){
    .kalniyojanPolicyExplaination ol li{
        width: 90%;
    }
}

@media (max-width: 430px){
    .kalniyojanPolicyExplaination ol li{
        width: 85%;
    }
}

@media (max-width: 400px){
    .kalniyojanPolicyExplaination ol{
        list-style: none;
    }

    .kalniyojanPolicyExplaination > ol > li {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
}