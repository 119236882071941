.kalniyojanAdminContainerArticleContent{
    display: none;
    transform: translateX(-100%);
    z-index: 0;
}

.kalniyojanAdminContainerArticleContentSelected{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
    transform: translateX(0);
    transition: all .5s ease-in-out;
}

.kalniyojanAdminContainerArticleContentSelected > .emptyArticleList{
    font-size: 30px;
}

.kalniyojanAdminContainerArticleContentSelected > .articleListErrorShower{
    display: none;
}

.kalniyojanAdminContainerArticleContentSelected > .articleListErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanAdminContainerArticleContentSelected > .articleDeleteErrorShower{
    display: none;
}

.kalniyojanAdminContainerArticleContentSelected > .articleDeleteErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanAdminContainerArticleContentSelected > table > thead > tr{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: "Amaranth";
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.kalniyojanAdminTableBody{
    text-align: center;
    font-size: 15px;
    padding: 10px;
    font-family: "Amaranth";
}

.kalniyojanAdminTableBody > tr > td > .kalniyojanAdminDownloadArticleBtn{
    padding: 5px;
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    font-size: 13px;
}

.kalniyojanAdminTableBody > tr > td > .kalniyojanAdminDownloadArticleBtn:hover{
    cursor: pointer;
}

.kalniyojanAdminTableBody > tr > .adminArticleActionBtns > button{
    background-color: white;
    padding: 4px;
    border: none;
}

.adminArticleActionBtns > button > img{
    width: 35px;
    height: 25px;
}

.adminArticleActionBtns > button > img:hover{
    cursor: pointer;
}

@media(max-width: 850px){
    .kalniyojanAdminContainerArticleContentSelected > .articleListErrorShowerSelected{
        width: 500px;
    }

    .kalniyojanAdminContainerArticleContentSelected > .articleDeleteErrorShowerSelected{
        width: 500px;
    }
}

@media(max-width: 555px){

    .kalniyojanAdminContainerArticleContentSelected > .articleListErrorShowerSelected{
        width: 350px;
    }

    .kalniyojanAdminContainerArticleContentSelected > .articleDeleteErrorShowerSelected{
        width: 350px;
    }
}

@media(max-width: 430px) {
    table > thead > tr > td{
        font-size: 14px;
    }

    table > tbody > tr > td{
        font-size: 12px;
    }
}

@media(max-width: 400px){

    .kalniyojanAdminContainerArticleContentSelected > .articleListErrorShowerSelected{
        width: 316px;
    }

    .kalniyojanAdminContainerArticleContentSelected > .articleDeleteErrorShowerSelected{
        width: 316px;
    }
}

@media(max-width: 380px){
    table.kalniyojanAdminArticleTable > thead > tr > td:nth-child(3){
        display: none;
    }
    
    table.kalniyojanAdminArticleTable > tbody > tr > td:nth-child(3){
        display: none;
    }
}

@media(max-width: 360px){

    .kalniyojanAdminContainerArticleContentSelected > .articleListErrorShowerSelected{
        width: 290px;
    }

    .kalniyojanAdminContainerArticleContentSelected > .articleDeleteErrorShowerSelected{
        width: 290px;
    }
}
