
.adminDashboardFeedbackTabShower{
    display: none;
    transform: translateX(-100%);
    z-index: 0;
}

.adminDashboardFeedbackTabShowerSelected{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    transform: translateX(0);
    transition: all .5s ease-in-out;
}

.adminDashboardFeedbackTabShowerSelected .adminDashboardFeedBackContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
}

.adminDashboardFeedBackContent .adminFeedbackErrorShower{
    display: none;
}

.adminDashboardFeedBackContent .adminFeedbackErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adminDashboardFeedBackContent table thead tr{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Amaranth";
    background: linear-gradient(180deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}


.adminDashboardFeedBackContent table thead tr td{
    padding: 2px;
}

.adminDashboardFeedBackContent table tbody{
    align-items: center;
    font-size: 15px;
    font-family: "Amaranth";
}

.adminDashboardFeedBackContent .adminFeedbackEmptyList{
    font-size: 26px;
    font-family: "Rubik";
}