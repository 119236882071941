.kalniyojanNavbarContainer{
    display: flex;
    flex-direction:column;
    background-color: #111111;
    color: #d8d6cf;
}

.kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
    font-size: 50px;
    text-transform: uppercase;
    margin: .3em .6em;
    font-family: iosevka;
}

.kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarSidebarButton > img:hover{
    cursor: pointer;
    transform: scale(.9);
}

.kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarSidebarButton{
    display: flex;
    margin-right: .8em;
}

.kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarSidebarButton:hover{
    cursor: pointer;
}

.kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarSidebarButton > img{
    height: 50px;
    width: 50px;
}


.kalniyojanNavbarBrand:hover{
    color: #FCD956;
    cursor: pointer;
}

@media(max-width:1370px){
    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 60px;
    }
}

@media(max-width:1240px){
    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 50px;
    }

}

@media(max-width:1140px){
    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 50px;
    }

}

@media(max-width: 990px) {

    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 45px;
    }
}

@media(max-width:940px){

    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        padding: 0;
        font-size: 40px;
        margin: .4em .6em;
    }

}

@media(max-width:900px){
    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 40px;
    }
}

@media(max-width:840px){
    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 40px;
    }
}

@media(max-width:770px){

    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 35px;
    }

    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarSidebarButton > img{
        margin-right: 10px;
        width: 35px;
        height: 35px;
    }

}

@media(max-width: 320px){
    .kalniyojanNavbarContainer > .kalniyojanNavbarBrandAndRightSideContent > .kalniyojanNavbarBrand{
        font-size: 30px;
    }
}
