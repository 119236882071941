
.kalniyojanHelpContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 266px);
}

.kalniyojanHelpContainer .kalniyojanHelpContainerContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.kalniyojanHelpContainerContent h1{
  font-size: 42px;
  font-family: "Rubik";
  margin-top: 2em;
  margin-bottom: 2em;
}

.kalniyojanHelpContainerContent .kalniyojanHelpTypes{
  display: grid;
  grid-template-columns: repeat(6,1fr);
  width: 800px;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 4em;
}

.kalniyojanHelpTypes .kalniyojanFeedback{
  grid-column: 1 / span 3;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 300px;
}

.kalniyojanHelpTypes .kalniyojanFeedback img{
  margin-top: 10px;
  width: 150px;
  height: 150px;
}

.kalniyojanFeedback h3{
  font-size: 32px;
  font-family: "Rubik";
}

.kalniyojanHelpTypes .kalniyojanFeedback:hover{
  cursor: pointer;
}

.kalniyojanHelpTypes .kalniyojanRefundCancellation{
  grid-column: 4 / span 3;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 300px;
}

.kalniyojanRefundCancellation img{
  margin-top: 10px;
  width: 150px;
  height: 150px;
}

.kalniyojanRefundCancellation h3{
  font-size: 32px;
  font-family: "Rubik";

}

.kalniyojanRefundCancellation:hover{
  cursor: pointer;
}

@media(max-width: 830px){
  .kalniyojanHelpContainerContent .kalniyojanHelpTypes{
    width: 95%;
  }
}

@media(max-width: 750px){

  .kalniyojanHelpTypes .kalniyojanRefundCancellation h3,
  .kalniyojanHelpTypes .kalniyojanFeedback h3{
    font-size: 25px;
  }
}

@media(max-width: 620px){
  .kalniyojanHelpContainerContent .kalniyojanHelpTypes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .kalniyojanHelpTypes .kalniyojanFeedback,
  .kalniyojanHelpTypes .kalniyojanRefundCancellation{
    width: 90%;
  }
}

@media(max-width: 400px){

  .kalniyojanHelpTypes .kalniyojanFeedback,
  .kalniyojanHelpTypes .kalniyojanRefundCancellation{
    width: 80%;
  }

}