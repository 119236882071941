.adminMatchmakingContainer{
    display: none;
    z-index: 0;
    transform: translateX(-100%);
}

.adminMatchmakingContainerSelected{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
    transform: translateX(0);
    transition: all .5s ease-in-out;
}

.adminMatchmakingContainerSelected > .adminMatchMakingContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 97%;
}

.adminMatchMakingContainerContent > .adminMatchmakingErrorShower{
    display: none;
}

.adminMatchMakingContainerContent > .adminMatchmakingErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.adminMatchMakingContainerContent table thead tr{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Amaranth";
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.adminMatchMakingContainerContent table thead tr td{
    padding: 2px;
}

.adminMatchMakingContainerContent table tbody{
    align-items: center;
    font-size: 15px;
    font-family: "Amaranth";
}

.adminMatchMakingContainerContent table tbody tr td{
    padding: 10px;
    text-align: center;
}

.adminMatchMakingContainerContent table tbody tr td .matchMakingAction button{
    background-color: #fff;
    border: none;
}

.adminMatchMakingContainerContent table tbody tr #matchMakingAction button img{
    width: 35px;
    height: 25px;
}

.adminMatchMakingContainerContent table tbody tr #matchMakingAction button:hover{
    cursor: pointer;
}

.adminMatchMakingContainerContent .adminEmptyMatchmaking{
    font-size: 30px;
}

@media (max-width: 780px){
    .adminMatchMakingContainerContent table tbody tr #matchMakingNameRow > #matchMakingNameFirstChild,
    .adminMatchMakingContainerContent table tbody tr #matchMakingNameRow > #matchMakingNameSecChild,
    .adminMatchMakingContainerContent table tbody tr #matchMakingEmailDateRow > #matchMakingEDFirstChild,
    .adminMatchMakingContainerContent table tbody tr #matchMakingEmailDateRow > #matchMakingEDSecChild,
    .adminMatchMakingContainerContent table tbody tr #matchMakingPhoneTimeRow > #matchMakingPTFirstChild,
    .adminMatchMakingContainerContent table tbody tr #matchMakingPhoneTimeRow > #matchMakingPTSecChild{
        border-bottom: 1px solid black;
    }

}

@media(max-width: 640px){
    .adminMatchMakingContainerContent table thead tr td{
        padding: 1.5px;
    }

    .adminMatchMakingContainerContent table tbody tr td{
        padding: 2px;
    }
}

@media(max-width: 540px){
    .adminMatchMakingContainerContent table thead tr #adminMatchmakingActionBtnHeader{
        display: none;
    }
    .adminMatchMakingContainerContent table tbody tr #matchMakingAction{
        display: none;
    }
}