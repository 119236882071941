.kalniyojanAdminContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    min-height: calc(100vh - 266px);
}
.kalniyojanAdminContainerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
    border-bottom: 4px double black;
}

.kalniyojanAdminContainer > .kalniyojanAdminContainerContent > .adminErrorShower{
    display: none;
}

.kalniyojanAdminContainer > .kalniyojanAdminContainerContent > .adminErrorShowerSelected{
    display: flex;
    flex-direction: column;
    width: 800px;
    margin-bottom: .5rem;
    font-family: "Amaranth";
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 1rem;
    background-color: rgb(247, 84, 84);
    font-size: 16px;
}

.kalniyojanAdminContainerContent > .adminDashboardHeader > h1{
    font-size: 1cm;
    font-family: Arial, Helvetica, sans-serif;
}

.kalniyojanAdminContainerContent > .kalniyojanArea{
    padding: 10px;
    display: flex;
    flex-direction: row;
    background-color: aliceblue;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    width: 97%;
    justify-content: center;
    font-family: "Rubik";
}

.kalniyojanArea > div{
    margin: 0 5px;
}

.kalniyojanArea > .kalniyojanAdminListArticle{
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .kalniyojanAdminListArticleSelect{
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .kalniyojanAdminListArticle:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .kalniyojanAdminAddArticle{
    grid-column: 3 / span 2;
    grid-row: 1 / span 3;
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .kalniyojanAdminAddArticleSelect{
    grid-column: 3 / span 2;
    grid-row: 1 / span 3;
    font-size: 16;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .kalniyojanAdminAddArticle:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .kalniyojanAdminMainpageImages{
    grid-column: 5 / span 2;
    grid-row: 1 / span 3;
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .kalniyojanAdminMainpageImagesSelect{
    grid-column: 5 / span 2;
    grid-row: 1 / span 3;
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .kalniyojanAdminMainpageImages:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .dashboardAdviceTab{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .dashboardAdviceTabSelect{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .dashboardAdviceTab:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .dashboardMatchmakingTab{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .dashboardMatchmakingTabSelect{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .dashboardMatchmakingTab:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .adminDashboardPatrikaTab{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .adminDashboardPatrikaTabSelect{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .adminDashboardPatrikaTab:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .adminDashboardMuhurtaTab{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .adminDashboardMuhurtaTabSelect{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .adminDashboardMuhurtaTab:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .adminDashboardFeedbackTab{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.kalniyojanArea > .adminDashboardFeedbackTabSelect{
    font-size: 16px;
    text-align: center;
    align-self: center;
    border-radius: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanArea > .adminDashboardFeedbackTab:hover{
    cursor: pointer;
    background-image: linear-gradient(45deg, #4ecdc4,#556270);
}

.kalniyojanAdminContainer > .LogoutBar{
    display: none;
    visibility: hidden;
}

.kalniyojanAdminContainer > .LogoutBarSelected{
    width: 99%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
}

.kalniyojanAdminContainer > .LogoutBarSelected > p > button{
    font-size: 16px;
    padding: 8px;
    border: none;
    border-radius: 5px;
}

.kalniyojanAdminContainer > .LogoutBarSelected > p > button:hover{
    cursor: pointer;
}

.kalniyojanAdminContainer > .kalniyojanAdminScreenSwitcher{
    display: flex;
    flex-direction: column;
    width: 99%;
    position: relative;
    margin-top: 2rem;
}

@media(max-width: 880px){
    .kalniyojanAdminContainerContent .kalniyojanArea{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: repeat(4,1fr);
        column-gap: 5px;
        row-gap: 5px;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminListArticle{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminListArticleSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminAddArticle{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminAddArticleSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminMainpageImages{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }
    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminMainpageImagesSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .dashboardAdviceTab{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 2 / span 1;
    }
    .kalniyojanAdminContainerContent > .kalniyojanArea > .dashboardAdviceTabSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 2 / span 1;
    }

    .kalniyojanAdminContainerContent .kalniyojanArea .dashboardMatchmakingTab{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .dashboardMatchmakingTabSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardPatrikaTab{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 3 / span 1;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardPatrikaTabSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 3 / span 1;
    }
    
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardMuhurtaTab{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 4 / span 1;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardMuhurtaTabSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 1 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardFeedbackTab{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardFeedbackTabSelect{
        font-size: 18px;
        font-family: "Rubik";
        grid-column: 3 / span 2;
        grid-row: 4 / span 1;
    }
}

@media(max-width: 850px){

    .kalniyojanAdminContainerContent .kalniyojanArea{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: repeat(4,1fr);
    }

    .kalniyojanArea .kalniyojanAdminListArticle{
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminListArticleSelect{
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminAddArticle{
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminAddArticleSelect{
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminMainpageImages{
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminMainpageImagesSelect{
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }

    .kalniyojanArea .dashboardAdviceTab{
        grid-column: 3 / span 2;
        grid-row:  2 / span 1;
    }

    .kalniyojanArea .dashboardAdviceTabSelect{
        grid-column: 3 / span 2;
        grid-row:  2 / span 1;
    }

    .kalniyojanArea .dashboardMatchmakingTab{
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .dashboardMatchmakingTabSelect{
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardPatrikaTab{
        grid-column: 3 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardPatrikaTabSelect{
        grid-column: 3 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardMuhurtaTab{
        grid-column: 1 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanArea .adminDashboardMuhurtaTabSelect{
        grid-column: 1 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanArea .adminDashboardFeedbackTab{
        grid-column: 3 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanArea .adminDashboardFeedbackTabSelect{
        grid-column: 3 / span 2;
        grid-row: 4 / span 1;
    }
}

@media(max-width: 560px){
    .kalniyojanAdminContainerContent .kalniyojanArea{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: repeat(4,1fr);
        row-gap: 10px;
    }

    .kalniyojanArea .kalniyojanAdminListArticle{
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminListArticleSelect{
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminAddArticle{
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminAddArticleSelect{
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminMainpageImages{
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }

    .kalniyojanArea .kalniyojanAdminMainpageImagesSelect{
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
    }

    .kalniyojanArea .dashboardAdviceTab{
        grid-column: 3 / span 2;
        grid-row:  2 / span 1;
    }

    .kalniyojanArea .dashboardAdviceTabSelect{
        grid-column: 3 / span 2;
        grid-row:  2 / span 1;
    }

    .kalniyojanArea .dashboardMatchmakingTab{
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .dashboardMatchmakingTabSelect{
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardPatrikaTab{
        grid-column: 3 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardPatrikaTabSelect{
        grid-column: 3 / span 2;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardMuhurtaTab{
        grid-column: 1 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanArea .adminDashboardMuhurtaTabSelect{
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
    }

    .kalniyojanArea .adminDashboardFeedbackTab{
        grid-column: 3 / span 2;
        grid-row: 4 / span 1;
    }

    .kalniyojanArea .adminDashboardFeedbackTabSelect{
        grid-column: 3 / span 2;
        grid-row: 4 / span 1;
    }

}

@media(max-width: 480px){

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminListArticle{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminListArticleSelect{
        font-size: 14px;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminAddArticle{
        font-size: 14px;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminAddArticleSelect{
        font-size: 14px;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminMainpageImages{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminMainpageImagesSelect{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent > .kalniyojanArea > .dashboardAdviceTab{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent > .kalniyojanArea > .dashboardAdviceTabSelect{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .dashboardMatchmakingTab{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .dashboardMatchmakingTabSelect{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardPatrikaTab{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardPatrikaTabSelect{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardMuhurtaTab{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardMuhurtaTabSelect{
        font-size: 14px;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardFeedbackTab,
    .kalniyojanAdminContainerContent .kalniyojanArea .adminDashboardFeedbackTabSelect{
        font-size: 14px;
    }
}

@media(max-width: 380px){
    .kalniyojanArea .dashboardAdviceTab{
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
    }
    .kalniyojanArea .dashboardAdviceTabSelect{
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
    }

    .kalniyojanArea .dashboardMatchmakingTab{
        grid-column: 4 / span 3;
        grid-row: 2 / span 1;
    }
    .kalniyojanArea .dashboardMatchmakingTabSelect{
        grid-column: 4 / span 3;
        grid-row: 2 / span 1;
    }

    .kalniyojanArea .adminDashboardPatrikaTab{
        grid-column: 1 / span 3;
        grid-row: 3 / span 1;
    }
    .kalniyojanArea .adminDashboardPatrikaTabSelect{
        grid-column: 1 / span 3;
        grid-row: 3 / span 1;
    }

    .kalniyojanArea .adminDashboardMuhurtaTab{
        grid-column: 4 / span 3;
        grid-row: 3 / span 1;
    }
    .kalniyojanArea .adminDashboardMuhurtaTabSelect{
        grid-column: 4 / span 3;
        grid-row: 3 / span 1;
    }
}

@media(max-width: 390px){

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminListArticle{
        font-size: smaller;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminListArticleSelect{
        font-size: smaller;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminAddArticle{
        font-size: smaller;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminAddArticleSelect{
        font-size: smaller;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminMainpageImages{
        font-size: smaller;
    }

    .kalniyojanAdminContainerContent > .kalniyojanArea > .kalniyojanAdminMainpageImagesSelect{
        font-size: smaller;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .dashboardAdviceTab{
        font-size: smaller;
    }
    .kalniyojanAdminContainerContent .kalniyojanArea .dashboardMatchmakingTab{
        font-size: smaller;
    }
}

@media(max-width: 330px){
    .kalniyojanAdminContainerContent > .adminDashboardHeader > h1{
        font-size: .8cm;
    }
}