.contactContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1cm;
    min-height: calc(100vh - 266px);
}

.contactContainer > .ContactContent{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin-top: 2rem;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(to bottom,#fffc00,#ffffff);
    border-radius: 15px;
}

.ContactContent > h1{
    font-size: 42px;
    color: rgb(75, 16, 16);
    align-items: baseline;
    text-align: center;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
}

.ContactContent > .panchangKarte{
    padding: 1rem;
    font-size: 24px;
    font-family: "Poppins";
}

.ContactContent > .panchangKarte > span{
    color: red;
    font-weight: 500;
    margin-bottom: 1rem;
}

.ContactContent > .panchangKarte > strong span{
    color: green;
}

.ContactContent > .paymentQrCode{
    margin-top: 1rem;
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width:720px){

    .contactContainer > .ContactContent{
        width: 500px;
    }

    .ContactContent > img{
        width: 450px;
        height: 450px;
        margin-left: auto;
        margin-right: auto;
    } 
}

@media(max-width:620px){

    .contactContainer > .ContactContent{
        width: 400px;
    }

    .ContactContent > img{
        width: 350px;
        height: 350px;
    } 

    .ContactContent > .panchangKarte{
        font-size: 22px;
        overflow-wrap: break-word;
    }
}

@media(max-width:520px){

    .contactContainer > .ContactContent{
        width: 350px;
    }

    .ContactContent > img{
        width: 280px;
        height: 280px;
    } 

    .ContactContent > .panchangKarte{
        font-size: 22px;
        overflow-wrap: break-word;
    }
}

@media(max-width:450px){

    .contactContainer > .ContactContent{
        width: 280px;
    }

    .ContactContent > img{
        width: 230px;
        height: 230px;
    } 

    .ContactContent > .paymentQrCode{
        width: 230px;
        height: 230px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width:400px){

    .contactContainer > .ContactContent{
        width: 240px;
    }

    .ContactContent > img{
        width: 230px;
        height: 230px;
    } 

}

@media(max-width:340px){

    .contactContainer > .ContactContent{
        width: 200px;
    }

    .ContactContent > img{
        width: 200px;
        height: 200px;
    } 

    .ContactContent > .paymentQrCode{
        width: 200px;
        height: 200px;
    }
}

@media(max-width:300px){

    .contactContainer > .ContactContent{
        width: 150px;
    }

    .ContactContent > img{
        width: 160px;
        height: 160px;
    } 

    .ContactContent > .paymentQrCode{
        width: 160px;
        height: 160px;
    }
}