.kalniyojanAdminAddArticleContainer{
  display: none;
  transform: translateX(-100%);
  z-index: 0;
}

.kalniyojanAdminAddArticleContainerSelected{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  padding: 10px;
  z-index: 1;
  transform: translateX(0);
  transition: all .5s ease-in-out;
}

.kalniyojanAdminAddArticleContainerSelected > .adminAddArticleErrorShower{
  display: none;
}

.kalniyojanAdminAddArticleContainerSelected > .adminAddArticleErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: ""Amaranth"";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanAdminAddArticleContainerSelected > form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 2rem;
}

.adminAddArticleNameRow{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.adminAddArticleNameRow > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.adminAddArticleNameRow > label > span{
  color: red;
}

.adminAddArticleNameRow > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adminAddArticlePublishDateRow{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  width: 100%;
  column-gap: 20px;
  margin-top: 10px;
}

.adminAddArticlePublishDateRow > div > label > span{
  color: red;
}

.adminAddArticlePublishDateRow > .adminArticlePublishYear{
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.adminArticlePublishYear > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminArticlePublishYear > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adminAddArticlePublishDateRow > .adminArticlePublishMonth{
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.adminArticlePublishMonth > label {
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminArticlePublishMonth > input{
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  width: 100%;
}

.adminAddArticlePublishDateRow > .adminArticlePublishDay{
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.adminArticlePublishDay > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminArticlePublishDay > input{
  width: 100%;
  height: 35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleImage{
  width: 600px;
}

.adminAddArticleImage > label{
  font-size: 16px;
  font-family: "Amaranth";
  font-weight: 100;
  align-self: flex-start;
}

.adminAddArticleImage > label > span{
  color: red;
}

.adminAddArticleImage > input{
  width: 100%;
  height: 35px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanAdminAddArticleSubmitBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.kalniyojanAdminAddArticleSubmitBtn > button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 850px){
  .kalniyojanAdminAddArticleContainerSelected > .adminAddArticleErrorShowerSelected{
    width: 500px;
  }
}

@media(max-width: 555px){
  .kalniyojanAdminAddArticleContainerSelected > .adminAddArticleErrorShowerSelected{
    width: 350px;
  }
}

@media(max-width: 400px){
  .kalniyojanAdminAddArticleContainerSelected > .adminAddArticleErrorShowerSelected{
    width: 316px;
  }
}

@media(max-width: 360px){
  .kalniyojanAdminAddArticleContainerSelected > .adminAddArticleErrorShowerSelected{
    width: 290px;
  }
}

@media(max-width: 680px){
  .kalniyojanAdminAddArticleContainerSelected > form{
    width: 400px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleImage{
    width: 400px;
  }
}

@media(max-width: 485px){
  .kalniyojanAdminAddArticleContainerSelected > form{
    width: 280px;
    padding: 1rem;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleImage{
    width: 280px;
  }
}

@media(max-width: 335px){
  .kalniyojanAdminAddArticleContainerSelected > form{
    width: 240px;
    padding: 1rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleNameRow > label{
    font-size: 14px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleNameRow > input{
    height: 25px;
    border-radius: 5px;
    margin-bottom: 8px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow{
    column-gap: 10px;
  }
  
  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow > .adminArticlePublishYear > label{
    font-size: 14px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow > .adminArticlePublishYear > input{
    height: 25px;
    border-radius: 5px;
    margin-bottom: 8px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow > .adminArticlePublishMonth > label{
    font-size: 14px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow > .adminArticlePublishMonth > input{
    height: 25px;
    border-radius: 5px;
    margin-bottom: 8px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow > .adminArticlePublishDay > label{
    font-size: 14px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticlePublishDateRow > .adminArticlePublishDay > input{
    height: 25px;
    border-radius: 5px;
    margin-bottom: 8px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleImage{
    width: 240px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .adminAddArticleImage > label{
    font-size: 14px;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .kalniyojanAdminAddArticleSubmitBtn{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .kalniyojanAdminAddArticleContainerSelected > form > .kalniyojanAdminAddArticleSubmitBtn > button{
    width: 75%;
  }
}