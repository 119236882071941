.kalniyojanPageNotFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2cm;
    margin-bottom: 2cm;
    padding: 3cm;
}

.kalniyojanPageNotFound > .kalniyojanPageNotFoundStatusCode{
    font-size: 3cm;
}

.kalniyojanPageNotFound > .kalniyojanPageNotFoundMessage{
    font-size: 2rem;
}

.kalniyojanPageNotFound > .kalniyojanPageNotFoundGoToHomeBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1cm;
    height: 50px;
    border-radius: 10px;
    box-shadow: 1px 1px rgba(0, 0, 0, 1.4);
    background-color: yellow;
    color: #357EC7;
    font-size: larger;
    font-weight: 700;
    cursor: pointer;
}

.kalniyojanPageNotFoundGoToHomeBtn:hover{
    transform: scale(1.1);
}