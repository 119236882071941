.homeContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 248.7953px);
}

.homeContainer .homeContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.4em;
    width: 95%;
    margin-bottom: 2em;
}

.homeImage{
    padding: 1em;
    border-radius: 9px;
}

.homePageDefaultImage{
    width: 100%;
    border-radius: 9px;
}

.frontImage{
    width: 100%;
}

.frontBackImage{
    width: 100%;
    border-radius: 15px;
}

.backBackImage{
    width: 100%;
    border-radius: 15px;
}

.backImage{
    width: 100%;
}