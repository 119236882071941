.kalniyojanFooterContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 1cm;
    background-color: #111111;
    position: relative;
}

.kalniyojanFooterContainer > .kalniyojanCopyRight{
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: iosevka;
    margin-bottom: 1.4em;
}

.kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: gray;
}

.kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights > .copyRight{
    font-size: 20px;
}

.kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights > .reserved{
    font-size: 20px;
    margin-top: 1em;
}

.kalniyojanFooterContainer > .kalniyojanHelpTermPolicyFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1.4em;
}

.kalniyojanHelpTermPolicyFooter div{
    color: grey;
    text-decoration: none;
    font-family: iosevka;
    font-weight: 700;
    font-size: 20px;
    margin: .6em;
}

.kalniyojanHelpTermPolicyFooter div:hover{
    cursor: pointer;
    color: rgb(99, 98, 98);
    text-decoration: underline;
}

.kalniyojanFooterContainer > .visitorCounter{
    display: flex;
    flex-direction: row;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px;
    color: white;
    font-family: iosevka;
    margin-bottom: 1.4em;
}

.kalniyojanFooterContainer > .developer{
    font-size: 14px;
    color: gray;
    position: absolute;
    right: 18px;
    bottom: 2px;
    font-family: iosevka;
}

.developer > strong{
    font-size: 14px;
}

@media(max-width: 900px){

    .kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights > .copyRight,
    .kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights > .reserved,
    .kalniyojanHelpTermPolicyFooter > div, .visitorCounter > div{
        font-size: 14px;
    }

    .kalniyojanFooterContainer > .kalniyojanHelpTermPolicyFooter > div{
        text-decoration: underline;
    }

}

@media(max-width: 500px){

    .kalniyojanFooterContainer{
        flex-direction: column;
    }

    .kalniyojanFooterContainer > .kalniyojanCopyRight{
        margin-bottom: 0;
    }

    .kalniyojanFooterContainer > .kalniyojanHelpTermPolicyFooter{
        margin-top: 0;
    }

    .kalniyojanFooterContainer > .visitorCounter{
        margin-bottom: 2em;
    }

    .kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights > .copyRight,
    .kalniyojanFooterContainer > .kalniyojanCopyRight > .reservedRights > .reserved,
    .kalniyojanHelpTermPolicyFooter > div, .visitorCounter > div{
        font-size: 20px;
    }

}