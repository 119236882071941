.kalniyojanCheckoutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.kalniyojanCheckoutContainer .kalniyojanCheckoutContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.kalniyojanCheckoutContainerContent .stripePaymentErrorShower{
    display: none;
}

.kalniyojanCheckoutContainerContent .stripePaymentErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanCheckoutContainerContent .stripePaymentCancelBtns{
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-around;
}

.kalniyojanCheckoutContainerContent .stripePaymentCancelBtns .kalniyojanPaymentBtn{
    font-family: "Rubik";
    font-size: 18px;
    padding: 8px;
    background-color: #32cd32b0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    width: 120px;
}

.kalniyojanCheckoutContainerContent .stripePaymentCancelBtns .kalniyojanPaymentCancelBtn{
    font-family: "Rubik";
    font-size: 18px;
    padding: 8px;
    border: none;
    background-color: #ff4500;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    width: 120px;
}

.kalniyojanCheckoutContainerContent .stripePaymentCancelBtns .kalniyojanPaymentBtn:hover, .kalniyojanCheckoutContainerContent .stripePaymentCancelBtns .kalniyojanPaymentCancelBtn:hover{
    cursor: pointer;
}