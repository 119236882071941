.kalniyojanArticleContainer{
    display: flex;
    flex-direction: column;
    padding: .5cm;
    min-height: calc(100vh - 266px);
}

.kalniyojanArticleContainer > .kalniyojanArticleContainerContent{
    display: flex;
    flex-direction: column;
    width: 99%;
    justify-content: center;
    align-items: center;
    margin-top: 1.9cm;
}

.kalniyojanArticleContainerContent > .articleTitle{
    font-size: 42px;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
    text-align: center;
}

.kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseArticleListErrorShower{
    display: none;
}

.kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseArticleListErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

.kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseEmptyArticleList{
    font-size: 30px;
}

.kalniyojanArticleContainerContent > table > thead > tr{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: "Amaranth";
    background: linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

table, tr, td{
    border: 1px solid black;
    border-collapse: collapse;
}

tr > td{
    padding: 10px;
}

.kalniyojanTableBody > tr{
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-family: "Amaranth";
}

.kalniyojanTableBody > tr > td > button{
    padding: 5px;
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    font-size: 13px;
}

.kalniyojanTableBody > tr > td > button:hover{
    cursor: pointer;
}

@media(max-width: 880px){
    .kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseArticleListErrorShowerSelected{
        width: 500px;
    }
}

@media(max-width: 555px){
    .kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseArticleListErrorShowerSelected{
        width: 350px;
    }
}

@media(max-width: 400px){
    .kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseArticleListErrorShowerSelected{
        width: 316px;
    }
}

@media(max-width: 360px){
    .kalniyojanArticleContainer > .kalniyojanArticleContainerContent > .baseArticleListErrorShowerSelected{
        width: 290px;
    }
}

@media(max-width: 350px){
    table > tr > td{
        padding: 0;
    }
}