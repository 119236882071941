.kalniyojanJanmapatrikaContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 266px);
}

.kalniyojanJanmapatrikaContainer .kalniyojanJanmapatrikaContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98%;
}

.kalniyojanJanmapatrikaContainerContent h2{
  text-align: center;
  font-size: 32px;
  font-family: "Rubik";
  font-weight: normal;
  margin: 1em 1em;
}

.kalniyojanJanmapatrikaContainerContent .janmaPatrikaErrorShower{
  display: none;
}

.kalniyojanJanmapatrikaContainerContent .janmaPatrikaErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
  margin-bottom: 1em;
}

.kalniyojanJanmapatrikaContainerContent #kalniyojanJanmaPatrikaFeeIndicator{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(30, 221, 30, 0.281);
  font-family: "Amaranth";
  text-decoration: underline;
}

.kalniyojanJanmapatrikaContainerContent #janmaPatrikaPaymentErrorShower{
  display: none;
}

.kalniyojanJanmapatrikaContainerContent #janmaPatrikaPaymentErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
}

#kalniyojanJanmaPatrikaFeeIndicator div{
  font-size: 21px;
}

.kalniyojanJanmapatrikaContainerContent form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 90%;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

form .nameOnPatrikaGenderRow{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 1em;
    width: 97%;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    margin-bottom: 1em;
}

.nameOnPatrikaGenderRow .nameOnPatrikaRow{
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 3;
}

.nameOnPatrikaRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.nameOnPatrikaRow label span{
    color: red;
}

.nameOnPatrikaRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.nameOnPatrikaGenderRow .genderOnPatrikaRow{
    display: flex;
    flex-direction: column;
    grid-column: 4 / span 1;
}

.genderOnPatrikaRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.genderOnPatrikaRow label span{
    color: red;
}

.genderOnPatrikaRow select{
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  height: 40px;
}

form .kalniyojanPatrikaDateTimeRow{
  display:grid;
  grid-template-columns: repeat(6,1fr);
  column-gap: 1em;
  width: 97%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  margin-bottom: 1em;
}

.kalniyojanPatrikaDateTimeRow .kalniyojanPatrikaDateRow{
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
}

.kalniyojanPatrikaDateRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaDateRow label span{
  color: red;
}

.kalniyojanPatrikaDateRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanPatrikaDateTimeRow .kalniyojanPatrikaTimeRow{
  display: flex;
  flex-direction: column;
  grid-column: 3 / span 2;
}

.kalniyojanPatrikaTimeRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaTimeRow label span{
  color: red;
}

.kalniyojanPatrikaTimeRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanPatrikaDateTimeRow .kalniyojanPatrikaLanguageRow{
  display: flex;
  flex-direction: column;
  grid-column: 5 / span 2;
}

.kalniyojanPatrikaLanguageRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaLanguageRow label span{
  color:red;
}

.kalniyojanPatrikaLanguageRow select{
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  height: 40px;
}

form .kalniyojanPatrikaPlaceCountryRow{
  display: grid;
  grid-template-columns: repeat(6,1fr);
  column-gap: 1em;
  width: 97%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  margin-bottom: 1em;
}

.kalniyojanPatrikaPlaceCountryRow .kalniyojanPatrikaPlace{
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
}

.kalniyojanPatrikaPlace label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaPlace label span{
  color:red;
}

.kalniyojanPatrikaPlace input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanPatrikaPlaceCountryRow .kalniyojanPatrikaCountryRow{
  display: flex;
  flex-direction: column;
  grid-column: 3 / span 2;
}

.kalniyojanPatrikaCountryRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaCountryRow label span{
  color:red;
}

.kalniyojanPatrikaCountryRow select{
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  height: 40px;
}

.kalniyojanPatrikaPlaceCountryRow .kalniyojanPatrikaTier{
  grid-column: 5 / span 2;
  display: flex;
  flex-direction: column;
}

.kalniyojanPatrikaTier label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaTier label span{
  color:red;
}

.kalniyojanPatrikaTier select{
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
  height: 40px;
}

.kalniyojanPatrikaTier .patrikaTierKnowMore{
  justify-self: self-end;
  font-size: 12px;
  text-transform: capitalize;
}

form .kalniyojanPatrikaPhoneEmailRow{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 1em;
  width: 97%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  margin-bottom: 1em;
}

.kalniyojanPatrikaPhoneEmailRow .kalniyojanPatrikaPhoneRow{
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
}

.kalniyojanPatrikaPhoneRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaPhoneRow label span {
  color:red;
}

.kalniyojanPatrikaPhoneRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

.kalniyojanPatrikaPhoneRow p{
  justify-self: self-end;
  font-size: 12px;
  text-transform: capitalize;
  color: red;
  margin-top: 0;
}

.kalniyojanPatrikaPhoneEmailRow .kalniyojanPatrikaEmailRow{
  display: flex;
  flex-direction: column;
  grid-column: 3 / span 2;
}

.kalniyojanPatrikaEmailRow label{
  align-self: self-start;
  font-size:16px;
  font-family: "Amaranth";
  font-weight: 100;
}

.kalniyojanPatrikaEmailRow label span {
  color:red;
}

.kalniyojanPatrikaEmailRow input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108, 75, 137, 1);
  margin-bottom: 10px;
}

form .kalniyojanJanmapatrikaSubmitBtn{
  display: flex;
  flex-direction: column;
  margin-top: 1cm;
}

.kalniyojanJanmapatrikaSubmitBtn > button{
  font-size: 14px;
  font-family: "Amaranth";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
}

.kalniyojanJanmapatrikaSubmitBtn > button:hover{
    cursor: pointer;
    transform: scale(.9);
}

.kalniyojanJanmapatrikaContainer .janmaPatrikaPaymentOptionsWindow{
  display: none;
  transform: translateX(-100%);
  z-index: 0;
}

.kalniyojanJanmapatrikaContainer .janmaPatrikaPaymentOptionsWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #fff;
}

.janmaPatrikaPaymentOptionsWindowSelected div{
    width: 300px;
    height: fit-content;
}

.janmaPatrikaPaymentOptionsWindowSelected div p{
    font-family: "Rubik";
    font-size: 30px;
}

.janmaPatrikaPaymentOptionsWindowSelected div button{
    width: inherit;
    font-family: "Poppins";
    padding: 10px;
    font-size: 16px;
    background-color: #ACDDB8;
    border: none;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.janmaPatrikaPaymentOptionsWindowSelected div button:hover{
  cursor: pointer;
}

.kalniyojanJanmapatrikaContainer .patrikaPaymentWindow{
  display: none;
  transform: translateX(-100%);
}

.kalniyojanJanmapatrikaContainer .patrikaPaymentWindowSelected{
    position: fixed;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    height: 100%;
}

@media(max-width: 750px){

  .kalniyojanJanmapatrikaContainer .janmaPatrikaPaymentOptionsWindowSelected{
    flex-direction: column;
  }

  .kalniyojanJanmapatrikaContainer .janmaPatrikaPaymentOptionsWindowSelected div:first-child{
    margin-bottom: 10px;
  }
}

@media(max-width: 650px){

  .kalniyojanJanmapatrikaContainer .patrikaPaymentWindow{
    display: none;
    transform: translateY(-100%);
  }

  .kalniyojanJanmapatrikaContainer .patrikaPaymentWindowSelected{
    position: fixed;
    transform: translateY(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    height: 50%;
  }

  .kalniyojanJanmapatrikaContainerContent .janmaPatrikaErrorShowerSelected{
    width: 80%;
  }

  .kalniyojanJanmapatrikaContainerContent #janmaPatrikaPaymentErrorShowerSelected{
    width: 80%;
  }
}

@media(max-width: 520px){
  .kalniyojanJanmapatrikaContainerContent form .kalniyojanPatrikaDateTimeRow{
    display: flex;
    flex-direction: column;
  }
}

@media(max-width: 500px){
  .kalniyojanJanmapatrikaContainerContent form .kalniyojanPatrikaPhoneEmailRow{
    display: flex;
    flex-direction: column;
  }

  .kalniyojanJanmapatrikaContainerContent form{
    width: 85%;
  }
}

@media(max-width: 440px){
  .kalniyojanJanmapatrikaContainerContent form .kalniyojanPatrikaPlaceCountryRow{
    display: flex;
    flex-direction: column;
  }
}

@media(max-width: 300px){
  .kalniyojanJanmapatrikaContainerContent form .nameOnPatrikaGenderRow{
    display: flex;
    flex-direction: column;
  }
}