
.kalniyojanVastuContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 266px);
}

.kalniyojanVastuContainer .kalniyojanVastuContainerContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.kalniyojanVastuContainerContent > h2{
    font-size: 32px;
    font-family: "Rubik";
    font-weight: normal;
    margin: 1em 1em;
    text-align: center;
}

.kalniyojanVastuContainerContent > .vastuPaymentErrorShower{
  display: none;
}

.kalniyojanVastuContainerContent > .vastuPaymentErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
  margin-bottom: 1em;
  text-align: center;
}

.kalniyojanVastuContainerContent > .vastuErrorShower{
  display: none;
}

.kalniyojanVastuContainerContent > .vastuErrorShowerSelected{
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: .5rem;
  font-family: "Amaranth";
  text-transform: uppercase;
  align-items:center;
  justify-content:center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  background-color: rgb(247, 84, 84);
  font-size: 16px;
  margin-bottom: 1em;
  text-align: center;
}

.kalniyojanVastuContainerContent > .kalniyojanVastuFeesIndicator{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  padding: 1rem;
  font-size: 22px;
  margin-bottom: 1rem;
  background-color: rgba(30, 221, 30, 0.281);
  font-family: "Amaranth";
  text-decoration: underline;
}

.kalniyojanVastuContainerContent > form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 900px;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuNameRow{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.kalniyojanVastuNameRow > label{
  font-family: "Amaranth";
}

.kalniyojanVastuNameRow > label > span{
  color: red;
}

.kalniyojanVastuNameRow > input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuEmailRow{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.kalniyojanVastuEmailRow > label{
  font-family: "Amaranth";
}

.kalniyojanVastuEmailRow > label > span{
  color: red;
}

.kalniyojanVastuEmailRow > input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
  margin-bottom: 10px;
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuPhoneRow{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.kalniyojanVastuPhoneRow > label{
  font-family: "Amaranth";
}

.kalniyojanVastuPhoneRow > label > span{
  color: red;
}

.kalniyojanVastuPhoneRow > input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
}

.kalniyojanVastuPhoneRow > .kalniyojanVastuPhoneFormatNote{
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuAddressRow{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.kalniyojanVastuAddressRow > label{
  font-family: "Amaranth";
}

.kalniyojanVastuAddressRow > label > span{
  color: red;
}

.kalniyojanVastuAddressRow > input{
  height:35px;
  border-radius: 8px;
  border-color: rgba(108,75,137,1);
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuVisitRow{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.kalniyojanVastuVisitRow > label{
  font-family: "Rubik";
  margin-bottom: 8px;
  text-transform: capitalize;
  color: green;
}

.kalniyojanVastuVisitRow > input{
  margin-bottom: 10px;
}

.kalniyojanVastuContainerContent > form > .kalniyojanClientVastuFile{
  display: flex;
  flex-direction: row;
}

.kalniyojanClientVastuFile > label{
  font-family: "Rubik";
  margin-bottom: 10px;
  text-transform: capitalize;
}

.kalniyojanClientVastuFile > label > span{
  color: red;
}

.kalniyojanClientVastuFile > input{
  margin-bottom: 10px;
  margin-left: 10px;
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuSubmitBtn > button{
  font-size: 14px;
  font-family: "Rubik";
  padding: 10px;
  background-color: lightgreen;
  border-radius: 8px;
  margin-top: 2em;
}

.kalniyojanVastuContainerContent > form > .kalniyojanVastuSubmitBtn > button:hover{
  cursor: pointer;
  transform: scale(.9);
}

@media(max-width: 950px){

  .kalniyojanVastuContainerContent > form{
    width: 90%;
  }
}

@media(max-width: 650px){

  .kalniyojanVastuContainerContent > .vastuPaymentErrorShowerSelected,
  .kalniyojanVastuContainerContent > .vastuErrorShowerSelected{
    width: 80%;
  }

}

@media(max-width: 470px){

  .kalniyojanVastuContainerContent > form{
    width: 80%;
  }

  .kalniyojanVastuContainerContent > form > .kalniyojanClientVastuFile{
    flex-direction: column;
  }
}